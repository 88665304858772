import {api} from '@/helpers/api';
import Loading from 'quasar/src/plugins/Loading.js';import Notify from 'quasar/src/plugins/Notify.js';;
// import Notify from 'quasar/src/plugins/Notify.js';import Loading from 'quasar/src/plugins/Loading.js';;
import router from '@/router/'

const state = {

    userinput:{
        selectlanguage:null,
        agreedata:null,
        lastupdate:null,
        lasturl:null,
        presurvey:{},
        postsurvey: {},
        rational:{}

    },
    data:{
        info:null,
        templatetrans:null,
        welcome:null,
        presurvey:null,
        rational:null,
        postsurvey:null
    },
    loading:{
        info:null,
        templatetrans:null,
        welcome:null,
        presurvey:null,
        rational:null,
        postsurvey:null,

    }
};

const getters = {
    getdata:(state)=>(key)=>{
        if(state.loading[key]===null || state.loading[key]) return null

        return state.data[key]
    },
    selectlanguage:(state)=> state.userinput.selectlanguage,
    getuserinput:(state)=>(key)=>{
        // if(state.loading[key]===null || state.loading[key]) return null

        return state.userinput[key]
    },
};

const actions = {
    async ReloadAll({state,dispatch}){
        Loading.show({message:"Reloading data"});
        await dispatch('getkey',"info");
        if (state.userinput.selectlanguage) await dispatch('setLanguage',{langid:state.userinput.selectlanguage,reentry:false});
        await dispatch('getkey',"welcome");
        await dispatch('getkey',"presurvey");
        await dispatch('getkey',"rational");

        await dispatch('getkey',"postsurvey");

        Loading.hide()
    },
    async clearSurvey({commit}){
        await commit ("clearstate");

    },
    async getUserInput({commit}){
        const payload = await api.getuserinput();
        if (payload.status==="OK"&&payload.answers){
            Notify.create({
                type: 'positive',
                message: "Welcome back"
            });
            commit('loadUserInput',payload.answers)
        }
    },
    async setUserInput({commit,state,rootState},{field,value}){
        await commit ('setInput',{field:field,value:value});
        await commit ('setInput',{field:"lastupdate",value:Date.now()});
        await commit ('setInput',{field:"lasturl",value:router.history.current.path});
        if(!rootState.cauth.user.test){
            await api.updateuserinput(state.userinput);
        }
    },
    async setLanguage({commit,dispatch},{langid,reentry}){
        await commit ('setLoading',{field:"templatetrans",value:true});
        if(!reentry) dispatch ('setUserInput',{field:"selectlanguage",value:langid}); //avoid to erase lasturl by rewriting user input
        const payload = await api.setlang(langid);
        await commit ('setData',{field:"templatetrans",value:payload.langtrans});
        await dispatch ('cauth/setToken',payload.token,{root:true})
        await commit ('setLoading',{field:"templatetrans",value:false});
        // Load variable in cache
        if(!reentry) {
            dispatch('getkey', "welcome")
            dispatch('getkey', "presurvey")
            dispatch('getkey', "rational")
            dispatch('getkey', "postsurvey")
        }

    },
    async getkey({commit},key){
        await commit ('setLoading',{field:key,value:true});
        let payload={}
        if (key==="info"){
             payload = await api.surveyinfo();
        } else {
             payload = await api.surveygetkey(key);
        }
        if(payload.message === key){
             await commit ('setData',{field:key,value:payload.payload});
        }
        await commit ('setLoading',{field:key,value:false});

    },
    async loadkey({state,dispatch},key){
        if(state.loading[key]===null){
            if (key==="templatetrans"){
                if(state.userinput.selectlanguage) {
                    await dispatch("setLanguage", state.userinput.selectlanguage);
                } else {
                    router.push({name:"SurveyIndex"})
                }

            }
           await  dispatch("getkey",key);
        }
    }

};

const mutations = {
    loadUserInput(state,value){
        state.userinput=value;
    },
    setInput(state,{field,value}){
      state.userinput[field]=value;
    },
    setData(state,{field,value}){
        state.data[field]=value;
    },
    setLoading(state,{field,value}){
        state.loading[field]=value;
    },
    clearstate(state){
        state.userinput=
            {
                selectlanguage: null,
                agreedata: null,
                lastupdate: null,
                lasturl: null,
                presurvey: {},
                postsurvey: {},
                rational: {},

            };
            state.data=
                {
                    info: null,
                    templatetrans: null,
                    welcome: null,
                    presurvey: null,
                    postsurvey: null,
                    rational: null,
            };
            state.loading= {
                    info: null,
                    language: null,
                    welcome: null,
                    presurvey: null,
                    postsurvey: null,
                    rational:null,

            };

    },
}
export default {
    namespaced:true,
    state,
    getters,
    actions,
    mutations,
};
