<template>
  <div class="">
    <div class="row q-gutter-md no-wrap">
      <div class="col-1 ">
      </div>
      <div class="col-auto ">
        {{poc.firstname}}
      </div>
      <div class="col-auto ">
        {{poc.lastname}}
      </div>
    </div>
    <div v-if="poc.address&&poc.address.street">
      <div class="row q-gutter-md">
        <div class="col-1">
          <q-icon name="home"/>
        </div>
        <div class="col-auto">
          {{poc.address.street}}
        </div>
      </div>
      <div class="row q-gutter-md no-wrap">
        <div class="col-1">
        </div>
        <div class="col-auto" v-if="poc.address.zipcode">
          {{poc.address.zipcode}}
        </div>
        <div class="col-auto" v-if="poc.address.city">
          {{poc.address.city}}
        </div>
      </div>
    </div>
    <div class="row q-gutter-md no-wrap">
      <div class="col-1">
        <q-icon name="email"/>
      </div>
      <div class="col-auto">
        <a :href="'mailto:'+poc.email" class="text-primary"> {{poc.email}}</a>
      </div>
    </div>
    <div class="row q-gutter-md no-wrap" v-if="poc.phonenumber">
      <div class="col-1">
        <q-icon name="phone"/>
      </div>
      <div class="col-auto">
        <a :href="'tel:'+poc.phonenumber" class="text-primary"> {{poc.phonenumber}}</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "poc",
  props:{
    poc:Object,
  }
}
</script>

<style scoped>

</style>
