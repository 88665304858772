<template>
  <div class="row window-height window-width justify-center items-center">
    <div class="col-7 row justify-center q-pa-md" v-if="message.title">
      <q-card  bordered  class="shadow-3  q-gutter-md">
        <q-card-section class="bg-accent text-white text-h5 text-center " >
          {{message.title}}
        </q-card-section>
        <q-card-section class="bg-grey-2  text-h5 text-center " >
          <span v-html="message.message"/>
        </q-card-section>
        <q-card-section class="text-center " v-if="message.poc">
          <poc :poc="message.poc" />
        </q-card-section>
      </q-card>
    </div>
  </div>
</template>

<script>
import Poc from "@/components/poc";

export default {
name: "AccessUrl",
  components: {Poc},

  created() {
    this.$store.dispatch('cauth/AccessIn', {surveyid:this.$route.params.surveyid,tokenid:this.$route.params.tokenid});
  },
  computed:{
    message:function () {return this.$store.state.cauth.message}
  }
}
</script>

<style scoped>

</style>
