<template>
<div> Not Implemented yet </div>
</template>

<script>
export default {
  name: "TokenUrl",
  created() {
    this.$store.dispatch('cauth/LogIn', this.$route.params.id);
  }
}
</script>

<style scoped>

</style>
