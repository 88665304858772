<template>
  <q-img :src=obj.elements.picture  contain @click="$emit('onImg')" :disable="disable" :style="'background-color:' + obj.elements.bk " spinner-color="primary">
    <q-icon class="absolute all-pointer-events"  size="sm" name="info" color="primary" style="top: 8px; left: 8px; z-index: 100;" @click.stop="$emit('onInfo')" v-if="!disable_i">
      <q-tooltip v-if="alt">
        {{template.READALT}}
      </q-tooltip>
      <q-tooltip v-else>
        {{template.READOBJ}}
      </q-tooltip>
    </q-icon>
                <div class="absolute-bottom  text-center" v-if="alt&&obj.elements.contents[0]&&obj.elements.contents[0].html&&obj.elements.contents[0].html.caption">
                 <div style="font-size: 1.2vh;  white-space: pre-line">  {{ obj.elements.contents[0].html.caption }}</div>
                </div>
                <div class="absolute-bottom  text-center" v-else-if="obj.elements.contents.html&&obj.elements.contents.html.caption">
                  <div style="font-size: 1.2vh;  white-space: pre-line"> {{ obj.elements.contents.html.caption }}</div>
                </div>
  </q-img>
</template>

<script>
export default {
name: "ImgItem",
  props:{
    obj : Object,
    alt:{
      type:Boolean,
      default : false
    },
    disable:{
      type:Boolean,
      default : false
    },
    disable_i:{
      type:Boolean,
      default : false
    },
  },
  computed: {
    template: function () {
      return this.$store.getters['csurvey/getdata']('templatetrans') || {}
    },
  }
}
</script>

<style scoped>

</style>
