<template>
  <q-layout view="hhh lpr fFf">
    <q-page-container>
      <router-view/>
    </q-page-container>
    <q-footer  flat class="bg-grey-8 text-white">
      <div class="row">
        <span class="justify-center">Eawag Survey -- All right reserved -- Version : {{version}} -- Developed by <a class="text-white " href="https://www.youmi-lausanne.ch" target="_blank">Youmi</a></span>
      </div>
    </q-footer>
  </q-layout>

</template>
<script>
import countries from "@/assets/countries";

export default {
  name: "App",
  data(){
    return {
      version : process.env.VUE_APP_VERSION
    }
  },
  methods:{
    //Used to generate the flags icons
    getImgUrl(img) {
      const countryCodes = countries.map(country => country.code)
      if (countryCodes.includes(img)) {
        const images = require.context('@/assets/flags', false, /\.svg$/);
        return images('./' + img + ".svg")
      }
      else {
        return null
      }
    },

  },
  created () {
    history.pushState(null, null, location.href);
    window.onpopstate = function () {
      history.go(1);
    };
    //Add the flag icons to quasar icons lib using flag: tag
    this.$q.iconMapFn = (iconName) => {
      if (iconName.startsWith('flag:') === true) {
        // we strip the "flag:" part
        const name = iconName.substring(5).toLowerCase();
        const icon =this.getImgUrl(name);
        if(icon) {
          return {icon:'img:'+icon}
        }
      }
    };
    setInterval(() =>{
      if(!this.$store.getters['cauth/isTokenValid']&&this.$store.getters['cauth/isAuthenticated']) {
        this.$store.dispatch('cauth/LogOut');
      }
    },3000)
  }
}
</script>

<style>
html {
  font-size: 0.75rem; /* 12px */
}

@media (min-width: 600px) {
  html {
    font-size: 0.875rem; /* 14px */
  }
}

@media (min-width: 920px) {
  html {
    font-size: 1rem; /* 16px */
  }
}
span > figure > img {
  max-width: 100%;
  height: auto !important;
}


</style>
