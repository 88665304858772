import { render, staticRenderFns } from "./MidValue.vue?vue&type=template&id=1472229c&scoped=true&"
import script from "./MidValue.vue?vue&type=script&lang=js&"
export * from "./MidValue.vue?vue&type=script&lang=js&"
import style0 from "./MidValue.vue?vue&type=style&index=0&id=1472229c&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1472229c",
  null
  
)

export default component.exports
import QStepper from 'quasar/src/components/stepper/QStepper.js';
import QStep from 'quasar/src/components/stepper/QStep.js';
import QStepperNavigation from 'quasar/src/components/stepper/QStepperNavigation.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QSlider from 'quasar/src/components/slider/QSlider.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QStepper,QStep,QStepperNavigation,QBtn,QCard,QSlider});
