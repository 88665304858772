<template>
    <q-stepper
        v-model="step"
        ref="stepper_swing"
        color="primary"
        class="stepper"
        header-class="hidden"
        flat
    >
        <q-step :name="1" title="Swing Presentation" icon="looks_one" :done="step >1" :disable="!firsttime" >
        <div class="row q-pa-md justify-center">
         <span class="col-11 " v-html="template_text.SWINGTEXT" ></span>
        </div>
        <q-stepper-navigation class="row q-pa-md q-gutter-md justify-end">
          <q-btn class="col-auto" @click="slidenext()" color="primary" :label="template_text.NEXT" />
        </q-stepper-navigation>
      </q-step>

      <q-step :name="2" title="Swing 1" icon="looks_one" :done="step > 2" >
        <div class="row q-pa-md justify-center">
          <span class="col-10 text-subtitle1" v-html="template_text.SWINGSORT" ></span>
          <q-btn class="col-auto" :label="template_text.HELPBTN" icon="help" @click="onHelp()"/>
        </div>
        <div class="row ">
        <div class="col-12 row  q-pa-sm" v-for="(obj,index) in objectives.children" :key="obj.short_name" >
          <q-card class="col-12 col-md-7 " bordered :style="(!root)?'background-color:'+objectives.elements.color:'' "
                  :class="{ swingcard: !swingorder.includes(index),swingcard_selected: swingorder.includes(index) }"
                  :disabled="swingorder.includes(index)" @click="onSwing(index)">

          <div class="row " >
            <div :class="'col-md-'+Math.max(Math.round(12/objectives.children.length),2)" class="col q-pa-sm" v-for="(obji,indexi) in objectives.children" :key="obji.short_name" >
              <q-card bordered  :class="(obj===obji)?'bg-green':''" class="">
                <img-item :obj="obji" @onInfo="onInfo(indexi)"/>
                <q-separator />
                <q-card-section >
                  <span class="value-text text-center">{{(obj===obji)?obji.elements.max:obji.elements.min}} {{obji.elements.unit[selectlang]}}</span>
                </q-card-section>
              </q-card>
              </div>
          </div>
            <div class="absolute-top-right text-h2 fa-bold bg-white col-auto" style="color:#e30251" v-if="swingorder.includes(index)">
              {{swingorder.indexOf(index)+1}}
            </div>
          </q-card>
        </div>
        <div class="row q-pa-sm col-12" ref="worstbox">
        <q-card disabled class="col-12 col-md-7" bordered :style="(!root)?'background-color:'+objectives.elements.color:''" :class="{swingcard_selected:swingorder.length===objectives.children.length}">
          <div class="row  ">
            <div :class="'col-md-'+Math.max(Math.round(12/objectives.children.length),2)" class="col q-pa-sm" v-for="(obji,indexi) in objectives.children" :key="obji.short_name" >
              <q-card bordered  class="">
                <div ref="imgitem">
                  <img-item :obj="obji" @onInfo="onInfo(indexi)"  />
                </div>

                <q-card-section >
                  <span class="value-text text-center">{{obji.elements.min}} {{obji.elements.unit[selectlang]}}</span>
                </q-card-section>
              </q-card>

            </div>
          </div>
          <div class="absolute-top-right text-h2 fa-bold bg-white col-auto" style="color:#e30251" v-if="swingorder.length===objectives.children.length">
            {{objectives.children.length+1}}
          </div>
          <div class="worsttext"  >
            <span>{{template_text.SWINGWORSTTEXT}}</span>
          </div>
        </q-card>
        </div>
      </div>
        <q-stepper-navigation class="row q-pa-md q-gutter-md justify-end" >
            <q-btn class="col-auto  "  @click="onRestartSwing" color="secondary" :label="template_text.RESTART" v-if="swingorder.length>0"/>
            <q-btn class="col-auto  " @click="slidenext()" color="primary" :label="template_text.NEXT" v-if="swingorder.length===objectives.children.length" />
        </q-stepper-navigation>
      </q-step>

      <q-step :name="3"
              title="Swing 2"
              :done="step > 3" >
        <div class="row q-pa-md justify-center">
          <span class="col-10 text-subtitle1" v-html="template_text.SWINGMOVESLIDER" ></span>
          <q-btn class="col-auto" :label="template_text.HELPBTN" icon="help" @click="onHelp()"/>
        </div>
        <div class="row">
          <div class="col-12 row  q-pa-sm q-gutter-xl" v-for="(swi,index) in swingorder" :key="swi" >
            <q-card class="col-12 col-md-6 " bordered :style="(!root)?'background-color:'+objectives.elements.color:''" >
              <div class="row ">
                <div class="col-auto text-h3 self-center  bg-primary text-white">
                  {{index+1}}.
                </div>
                <div class="col-11 row ">
                <div :class="'col-md-'+Math.max(Math.round(12/objectives.children.length),2)" class=" col q-pa-sm" v-for="(obji,indexi) in objectives.children" :key="obji.short_name" >
                  <q-card bordered  :class="(objectives.children[swi]===obji)?'bg-green':''" class="fit">
                    <img-item :obj="obji" @onInfo="onInfo(indexi)"/>
                    <q-separator />
                    <q-card-section >
                      <span class="value-text text-center">{{(objectives.children[swi]===obji)?obji.elements.max:obji.elements.min}} {{obji.elements.unit[selectlang]}}</span>
                    </q-card-section>
                  </q-card>

                </div>
                </div>

              </div>
            </q-card>
            <div class="col-11 col-md-4 justify-center q-pa-md q-gutter-md">
              <div class="col text-red text-center text-subtitle1" v-if="error[objectives.children[swi].short_name]">{{error[objectives.children[swi].short_name]}}</div>
              <q-slider :value="swingdata[objectives.children[swi].short_name]||0" @input="onSwingSlider(swi,index,$event)" :min="0" :max="100"  label label-always :disable="index===0"
                        :key="componentKey"  :markers="50" :marker-labels="smarker" marker-labels-class="value-text"/>
            </div>
          </div>
          <div class="col-12 row q-pa-sm q-gutter-xl" ref="worstbox1">
            <q-card class="col-12 col-md-6" bordered :style="(!root)?'background-color:'+objectives.elements.color:''" disabled>
              <div class="row  ">
                <div class=" col-auto text-h3 self-center  bg-primary text-white">
                  {{swingorder.length+1}}.
                </div>
                <div class="row col-11 ">
                <div :class="'col-md-'+Math.max(Math.round(12/objectives.children.length),2)" class="col q-pa-sm" v-for="(obji,indexi) in objectives.children" :key="obji.short_name" >
                  <q-card bordered  class="fit">
                    <div ref="imgitem1">
                     <img-item :obj="obji" @onInfo="onInfo(indexi)"/>
                    </div>
                    <q-separator />
                    <q-card-section >
                      <span class="value-text text-center">{{obji.elements.min}} {{obji.elements.unit[selectlang]}}</span>
                    </q-card-section>
                  </q-card>
                </div>
              </div>
              </div>
              <div class="worsttext" >
                <span>{{template_text.SWINGWORSTTEXT}}</span>
              </div>
            </q-card>
            <div class="col-12 col-md-4 justify-center self-center q-pa-md">
              <q-slider :value="0" :min="0" :max="100" label label-always disable :markers="50" :marker-labels="smarker" marker-labels-class="value-text"/>
            </div>
          </div>
        </div>
        <q-stepper-navigation class="row q-pa-md q-gutter-md justify-end" >
            <q-btn class="col-auto" @click="onFinishSwing" color="primary" :label="template_text.NEXT" />
        </q-stepper-navigation>
      </q-step>
</q-stepper>
</template>
<script>
import { defineComponent } from '@vue/composition-api'
import { normobject} from "@/helpers/generic";
import ImgItem from "@/components/ImgItem";
import popup_help from "@/components/popup_help";
import popup_card from "@/components/popup_card";

export default defineComponent({
     name: "Swing",
    components: {ImgItem},

       computed:{
    selectlang:function () {return this.$store.state.csurvey.userinput.selectlanguage},
    template_text: function(){return this.$store.getters['csurvey/getdata']('templatetrans')||{}},
    smarker:function(){return [{value:0,label:this.template_text.SWINGMIN},{value:50,label:this.template_text.SWINGHALF},{value:100,label:this.template_text.SWINGMAX}]},

  },
    props:{
    objectives:Object,
    root:{type:Boolean,default:false},
    rational:Object,
    firsttime:{type:Boolean,default:false},
  },
    data(){return {
        step:1,
        swingdata:{},
        swingorder:[],
        componentKey: 0,
        error:{},
        
    }},
    mounted(){
            this.objectives.children.forEach((o)=>{
        this.$set(this.swingdata, o.short_name, null)
      })
      if(!this.firsttime) this.slidegoto(2);
    },
  methods:{
        slidegoto(step){
      this.$refs.stepper_swing.goTo(step);
      window.scrollTo(0, 0);

    },
        slidenext(){
      this.$refs.stepper_swing.next();
      window.scrollTo(0, 0);
    },
        onRestartSwing(){ 
      this.swingdata={}
      this.step=2
      this.slidegoto(this.step)
      this.error={}
      this.swingorder=[]
    },

          onSwing(index){
      if(this.swingorder[this.swingorder.length-1]===index && this.swingorder.length<this.objectives.children.length-1){ // if reclick to the last element
        this.swingorder.pop();
        return
      }
      if (this.swingorder.includes(index)) return // prevent reclick to previous element
      this.swingorder.push(index)
      if(this.swingorder.length===this.objectives.children.length-1){
        for (let i = 0; i < this.objectives.children.length; i++){
          if(!this.swingorder.includes(i)){
            this.swingorder.push(i)
            break;
          }
        }
        this.swingorder.forEach((o,i)=>{
          let val=null
          if(i===0) val = 100;
          this.swingdata[this.objectives.children[o].short_name]=val
        })
      }
    },
         onSwingSlider(swingval,indexswing,value){
      if (indexswing===0 )return
      if(indexswing>0&&value >this.swingdata[this.objectives.children[this.swingorder[indexswing-1]].short_name]){
        this.componentKey += 1;
        this.swingdata[this.objectives.children[this.swingorder[indexswing]].short_name]=this.swingdata[this.objectives.children[this.swingorder[indexswing-1]].short_name]
        this.error[this.objectives.children[this.swingorder[indexswing]].short_name]=this.template_text.ERRHIGHER+" ("+(indexswing)+".)"
        return
      } else if (indexswing<this.swingorder.length-1&&value <this.swingdata[this.objectives.children[this.swingorder[indexswing+1]].short_name]) {
        this.componentKey += 1;
        this.swingdata[this.objectives.children[this.swingorder[indexswing]].short_name]=this.swingdata[this.objectives.children[this.swingorder[indexswing+1]].short_name]

        this.error[this.objectives.children[this.swingorder[indexswing]].short_name] = this.template_text.ERRLOWER+" ("+(indexswing+2)+".)"
        return
      }
       this.error[this.objectives.children[this.swingorder[indexswing]].short_name]=null
       this.swingdata[this.objectives.children[swingval].short_name]=value

     },
         onFinishSwing(){
      if(Object.values(this.swingdata).includes(null)) {
        Object.keys(this.swingdata).forEach(key => {
          if(!this.swingdata[key]) this.error[key]=this.template_text.NEEDVAL
        })
        this.componentKey += 1;
        return
        }
      //Normalization
      this.swingdata=normobject(this.swingdata)

      //Send results
      this.$emit('finish',this.swingdata)
      
    },
        onHelp(){
      this.$q.dialog({
        component: popup_help,
        tradeoff:false,
        persistent:false,
        template_text:this.template_text,
      });
    },
        onInfo(index){
      this.$q.dialog({
        component: popup_card,
        alt: this.objectives.children[index],
        obj:true,
        persistent:false,
      })
    },
  }
})
</script>
<style scoped>
.swingcard{

}
.swingcard_selected{
  border: 0.4rem solid #e30251;
}
.swingcard:hover{
  border: 0.2rem solid #027BE3;
  cursor: pointer;
}
.value-text{
  font-size: max(0.7vw, 10px);
}
.worsttext{
  position: absolute;
  top: 65%;
  left: 0px;
  width: 100%;
  height: 5vh;
  z-index: 100;
  background: #1D1D1D;
  opacity: 0.9;
  text-align: center;
  font-size: medium;
  color: #ffffff;
}
</style>