import Vue from 'vue'
import VueRouter from 'vue-router'

import store from "../store";

import Notify from 'quasar/src/plugins/Notify.js';

import Login from '@/views/Login'
import LayoutMain from '@/layouts/Main'
import SurveyIndex from "@/views/SurveyIndex";
import WelcomePage from "@/views/WelcomePage";
import PreSurvey from "@/views/PreSurvey";
import NotFoundComponent from "@/views/NotFoundComponent";
import TokenUrl from "@/views/TokenUrl";
import PostSurvey from "@/views/PostSurvey";
import RationalSurvey from "@/views/RationalSurvey";
import AccessUrl from "@/views/AccessUrl";
import RedirectUrl from "@/views/RedirectUrl";
import Conclusion_rational_print from "@/views/Conclusion_rational_print"




Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login,
    meta: { guest: true },
  },
  {
    path: '/token/:id',
    name: 'TokenUrl',
    component: TokenUrl,
    meta: { guest: true },
  },
  {
    path: '/access/:surveyid/:tokenid',
    name: 'AccessUrl',
    component: AccessUrl,
    meta: { guest: true },
  },
  {
    path: '/survey',
    name: 'LayoutMain',
    component: LayoutMain,
    meta: { requiresAuth: true },
    children: [
      { path: 'index.htm', name:'SurveyIndex',component: SurveyIndex },
      { path: 'welcome.htm',name: 'welcome',component: WelcomePage},
      { path: 'pre/:id',name: 'presurvey',component: PreSurvey},
      { path: 'post/:id',name: 'postsurvey',component: PostSurvey},
      { path: 'rs.htm',name: 'rational',component: RationalSurvey},

    ]
  },
  {
    path: '/surveyprint',
    name: 'SurveyPrint',
    component: Conclusion_rational_print,
    meta: { requiresAuth: true },
  },
  {
    path: '/redirect',
    name: 'redirecturl',
    component: RedirectUrl,
    meta: {requiresAuth: true},
  },
  { path: '*', component: NotFoundComponent }
]




const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (store.getters['cauth/isAuthenticated']) {
      next();
      return;
    }
    Notify.create({
      type: 'warning',
      message: "You need to login"
    });
    next("/");
  } else {
    next();
  }
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.guest)) {
    if (store.getters['cauth/isAuthenticated']) {
      next({name: "SurveyIndex"});
      return;
    }
    next();
  } else {
    next();
  }
});

export default router
