<template>
<div class="q-pa-md row  items-end ">
    <div class="col-12 col-md-4 q-pa-sm " v-for="(alt,index) in alternatives" :key="alt.short_name" >
      <q-img
          :src=alt.elements.picture
          @click="onImg(index)"
          v-bind:class="{ image: index<=enablebox }"
          :disabled="index>enablebox" 
                  spinner-color="primary">
        <div class="absolute-bottom  text-center">
          <div style="font-size: 1.2vh;  white-space: pre-line">{{ alt.elements.contents[0].html.caption }}</div>
        </div>
      </q-img>
    </div>
</div>
</template>

<script>
import popup_card from "@/components/popup_card";
export default {
  name: "AlternativesDisp",
  data(){return {
    enablebox:0,
  }},

  props:{
    alternatives:Array,
  },

  computed: {
    template: function () {
      return this.$store.getters['csurvey/getdata']('templatetrans') || {}
    },},
  methods:{
    onImg(index){
      if(index>this.enablebox) return //box is disabled so nothing happends
      this.$q.dialog({
        component: popup_card,
        alt: this.alternatives[index],
        persistent:true,
      }).onOk(() => {
        if(index===this.enablebox) this.enablebox++; //move to the next image
      })
      if(index===this.alternatives.length-1) this.$emit('continue') //send a continue event all images have been displayed

    },
  }
}
</script>

<style scoped>
.image{

}
.image:hover{
  border: 0.2rem solid #027BE3;
  cursor: zoom-in;
}

</style>
