<template>
  <q-card square bordered class="q-pa-lg shadow-1 q-gutter-md">
    <div class="text-subtitle2">Select your language :</div>
    <div class="column q-gutter-sm">
      <div class="col-md-6" v-for="lang in languages" :key="lang.id">
        <q-btn :icon="'flag:'+lang.flag" @click="onBtn(lang.id)" :label="lang.id" class="full-width" ></q-btn>
      </div>
    </div>
  </q-card>
</template>

<script>
export default {
name: "SelectLang",
  props:{
  languages:Array,
  },
  methods:{
  onBtn(langid){
    this.$emit('click', langid);
  },
  }
}
</script>

<style scoped>
  .q-card {
    width: 360px;
}
</style>
