<template>
<q-stepper
        v-model="step"
        ref="stepper_trade"
        color="primary"
        class="stepper"
        header-class="hidden"
        flat
    >
      <q-step :name="1" title="Tradeoff presentation" icon="looks_one" :done="step > 1"  :disable="!firsttime">
        <div class="row q-pa-md justify-center">
          <span class="col-11" v-html="(usestepwise)?template_text.TRADEOFFTEXTSTEPWISE:template_text.TRADEOFFTEXT" ></span>
        </div>
        <q-stepper-navigation class="row q-pa-md q-gutter-md justify-end">
          <q-btn class="col-auto" @click="slidenext()" color="primary" :label="template_text.NEXT" />
        </q-stepper-navigation>
      </q-step>

       <q-step :name="2"
              title='Tradeoff'
              :done="step >  (2)"
      >
         <div class="row q-pa-md justify-center">
           <span class="col-10 text-subtitle1" v-html="template_text.TRADESEL" v-if="!tmptradestep.selected"></span>
          <span class="col-10 text-subtitle1" v-html="template_text.TRADESELSTEPWISE" v-else-if="tmptradestep.stepwise"></span>
          <span class="col-10 text-subtitle1" v-html="template_text.TRADEMOVESLIDER" v-else></span>
           <q-btn class="col-auto" :label="template_text.HELPBTN" icon="help" @click="onHelp()"/>

         </div>


         <div class="row q-pa-md  justify-between" v-if="!tmptradestep.selected || tmptradestep.stepwise">

           <q-card class="col-md-4 col-12 row q-gutter-sm justify-center swingcard" bordered :style="(!root)?'background-color:'+objectives.elements.color:''" @click="onSelectTradeStep(n-1,0)" >
                     <q-card bordered  class="bg-green col-5" >
                       <img-item :obj="objectives.children[n-1]" @onInfo="onInfo(n-1)"/>
                       <q-separator />
                       <q-card-section >
                         <span class="value-text text-center">{{objectives.children[n-1].elements.max}} {{objectives.children[n-1].elements.unit[selectlang]}}</span>
                       </q-card-section>
                     </q-card>
                     <q-card bordered  class=" col-5" :class="(tmptradestep.item===1)?'bg-orange':''">
                       <img-item :obj="objectives.children[n]" @onInfo="onInfo(n)"/>
                       <q-separator />
                       <q-card-section >
                         <span class="value-text text-center">{{calchalf(n,tmptradestep.item===1)}} {{objectives.children[n].elements.unit[selectlang]}}</span>
                       </q-card-section>
                     </q-card>
            </q-card>

           <div class="col-md-2 col-12 text-h3 items-center self-center q-pa-md">
             <div class="column items-center">
               <div class="col">
                 {{template_text.VSTEXT}}
               </div>
               <div class="col-1">
                 <q-btn :label="template_text.EQUIVALENT" color="primary" @click="onTradeEquiStep(n-1,tmptradestep.stepwise)" />
               </div>
             </div>
            </div>

           <q-card class="col-md-4 col-12 row q-gutter-sm justify-center swingcard" bordered :style="(!root)?'background-color:'+objectives.elements.color:''" @click="onSelectTradeStep(n-1,1)">
             <q-card bordered  class=" col-5" :class="(tmptradestep.item===0)?'bg-orange':''">
               <img-item :obj="objectives.children[n-1]" @onInfo="onInfo(n-1)"/>
               <q-separator />
               <q-card-section >
                 <span class="value-text text-center">{{calchalf(n-1,tmptradestep.item===0)}} {{objectives.children[n-1].elements.unit[selectlang]}}</span>
               </q-card-section>
             </q-card>
             <q-card bordered  class="bg-green col-5">
               <img-item :obj="objectives.children[n]" @onInfo="onInfo(n)"/>
               <q-separator />
               <q-card-section >
                 <span class="value-text text-center">{{objectives.children[n].elements.max}} {{objectives.children[n].elements.unit[selectlang]}}</span>
               </q-card-section>
             </q-card>
           </q-card>
         </div>


     


         <div class="row q-pa-md  justify-between" v-else>

           <q-card class="col-md-5 col-12 row q-gutter-sm justify-center" bordered :style="(!root)?'background-color:'+objectives.elements.color:''"  >
             <q-card bordered  :class="(tmptradestep.item===0)?'bg-green':''" class="col-5" >
               <img-item :obj="objectives.children[n-1]" @onInfo="onInfo(n-1)"/>
               <q-separator />
               <q-card-section >
                 <span class="text-subtitle1 text-center">{{tmptradestep.item==0?objectives.children[n-1].elements.max:objectives.children[n-1].elements.min}} {{objectives.children[n-1].elements.unit[selectlang]}}</span>
               </q-card-section>
             </q-card>
             <q-card bordered  :class="(tmptradestep.item===1)?'bg-green':''" class=" col-5" >
               <img-item :obj="objectives.children[n]" @onInfo="onInfo(n)"/>
               <q-separator />
               <q-card-section >
                 <span class="text-subtitle1 text-center">{{tmptradestep.item==0?objectives.children[n].elements.min:objectives.children[n].elements.max}} {{objectives.children[n].elements.unit[selectlang]}}</span>
               </q-card-section>
               </q-card>
           </q-card>

           <div class="col-md-1 col-12 text-h3 items-center self-center q-pa-md text-center">
             =
           </div>
           <q-card class="col-md-5 col-12 row  justify-center" bordered :style="(!root)?'background-color:'+objectives.elements.color:''">

             <div class="col-3 self-center q-pa-md" v-if="tmptradestep.item===0" >
               <q-slider :key="KeyItem0" :reverse="(objectives.children[n-1].elements.min<objectives.children[n-1].elements.max)"
                         vertical
                         dense
                         :value="tmptradestep.value[2]"
                         :min="TOSlider('min',(n-1))"
                         :max="TOSlider('max',(n-1))"
                         :step="objectives.children[n-1].elements.step"
                         @change="onChangeSlider(2,n-1,$event)"
                         label-always label
                         snap
                         class="col"
                          style="z-index: 100"
               />
               <div class="col text-red text-center text-subtitle1" v-if="error">{{error}}</div>

             </div>

             <q-card bordered :class="(tmptradestep.item===1)?'bg-green':''" class=" col-4 q-pa-sm" >
                   <img-item  :obj="objectives.children[n-1]" @onInfo="onInfo(n-1)"/>

               <q-separator />
               <q-card-section >
                 <span class="value-text text-center">{{tmptradestep.value[2]}} {{objectives.children[n-1].elements.unit[selectlang]}}</span>
               </q-card-section>
             </q-card>
             <q-card bordered :class="(tmptradestep.item===0)?'bg-green':''" class="col-4 q-pa-sm" >
               <img-item :obj="objectives.children[n]" @onInfo="onInfo(n)"/>
               <q-separator />
               <q-card-section >
                 <span class="value-text text-center">{{tmptradestep.value[3]}} {{objectives.children[n].elements.unit[selectlang]}}</span>
               </q-card-section>
             </q-card>
             <div class="col-3 self-center q-pa-md" v-if="tmptradestep.item===1">
               <q-slider :reverse="(objectives.children[n].elements.min<objectives.children[n].elements.max)" :key="KeyItem1"
                         vertical
                         dense
                         :value="tmptradestep.value[3]"
                         :min="TOSlider('min',n)"
                         :max="TOSlider('max',n)"
                         :step="objectives.children[n].elements.step"
                         @change="onChangeSlider(3,n,$event)"
                         label-always label
                         snap
                         class = "col"
                         style="z-index: 100"
               />
             <div class="col text-red text-center text-subtitle1" v-if="error">{{error}}</div>
             </div>
           </q-card>

         </div>
         <q-stepper-navigation class="row q-pa-md q-gutter-md justify-end" v-if="tmptradestep.selected&&tmptradestep.clickslide">
           <q-btn class="col-auto" @click="onFinishTradeStep(n-1)" color="primary" :label="template_text.NEXT" />
         </q-stepper-navigation>


       </q-step>
</q-stepper>

</template>


<script>
import { defineComponent } from '@vue/composition-api'
import {tradeCalc, tradeV} from "@/helpers/tradeoff";
import ImgItem from "@/components/ImgItem";
import popup_help from "@/components/popup_help";
import popup_card from "@/components/popup_card";

export default defineComponent({
     name: "TradeOff",
    components: {ImgItem},
    mounted(){
            if(!this.firsttime) this.slidegoto(2);
    },
    computed:{
      selectlang:function () {return this.$store.state.csurvey.userinput.selectlanguage},
      template_text: function(){return this.$store.getters['csurvey/getdata']('templatetrans')||{}},
      usestepwise : function(){return  this.rational.general.tradeoff_method === 'stepwise'}

  },
    props:{
      objectives:Object,
      root:{type:Boolean,default:false},
      rational:Object,
      firsttime:{type:Boolean,default:false},
  },
    data(){return {
      error : null,
      n:1,
      step:1,
      tmptradestep:{
        selected:false,
        value:[],
        clickslide:false,
        stepwise:false,
        item:null,
        itemstepwise:null,
        nselect:null
      },
      tmptrademat:[],
      KeyItem0:0,
      KeyItem1:0,

    }},
      methods:{
        async FinishTrade(){
          // Called after the last step
          let mat = await tradeCalc(this.tmptrademat)
          let tmptrade = {}
          this.objectives.children.forEach((o,i)=>{tmptrade[o.short_name]=mat[i][0]})
          this.$emit('finish',tmptrade)
        },
        onChangeSlider(vn,n,value){
          this.tmptradestep.clickslide= true;
          let reset = false;
          if(this.usestepwise){
            let max = this.objectives.children[n].elements.max;
            let min = this.objectives.children[n].elements.min;
            let half = this.calchalf(n);
            if ((this.tmptradestep.itemstepwise === 0 && max > min &&  value < half) || (this.tmptradestep.itemstepwise === 1 && max < min && value < half)){
                  this.tmptradestep.clickslide=false;
                  this.error = this.template_text.ERRLOWERTOSTEPWISE;
                  value = half;
                  reset = true

            } else if ((this.tmptradestep.itemstepwise === 1 && max > min && value > half)  || (this.tmptradestep.itemstepwise === 0 && max < min &&  value > half)) {
                  this.tmptradestep.clickslide=false;
                  this.error = this.template_text.ERRHIGHERTOSTEPWISE;
                  value = half;
                  reset = true

            } else{
                  this. error = null;
                }
            }

          let tmpvalue = this.tmptradestep.value
          tmpvalue[vn] = value
          this.$set(this.tmptradestep,'value',tmpvalue);
          if(reset){
            this.KeyItem0 ++;
            this.KeyItem1 ++;
          }



        },
        calchalf(n,enabled=true){
          // if enabled return halg of objective values / if disabled return min
          let max = this.objectives.children[n].elements.max;
          let min = this.objectives.children[n].elements.min;
          let step = this.objectives.children[n].elements.step ?? 1;
          if (!enabled) return min
          let half = (max-min)/2.0+min;
          half = Math.ceil(half/step)*step; // round to the step
          return half
        },
      onRestartTrade(){
        this.tmptrademat=[];
        this.tmptradestep.selected=false;
        this.tmptradestep.stepwise=false;
        this.tmptradestep.item=null;
        this.tmptradestep.clickslide=false;
        this.step=2;
        this.n=1;
        this.slidegoto(this.step)

      },
      TOSlider(minmax,n){
        let max = this.objectives.children[n].elements.max;
        let min = this.objectives.children[n].elements.min;
        let step = this.objectives.children[n].elements.step ?? 1;

        if (min>max){ // invert min and max so slider react correctly

          const tmp = max;
          max = min;
          min = tmp + Math.sign(tmp)*step; // remove one step so we avoid the case of n-1 = n
        } else {
          max = max -Math.sign(max)*step; // remove one step so we avoid the case of n-1 = n
        }

        if (minmax==='min'){
          return min
        } else {
          return max
        }
      },

    async onFinishTradeStep(n){
      // Called after each trade off step
      let mat = []
      for(let i = 0; i < this.objectives.children.length; i++) {
        mat[i]=0;
      }
      for(let i = 0; i < 2; i++) {
        mat[n + i] = tradeV(this.tmptradestep.value[i], this.objectives.children[n + i].elements) - tradeV(this.tmptradestep.value[2 + i], this.objectives.children[n + i].elements);
      }
      this.tmptrademat.push(mat)
      this.tmptradestep.selected=false
      this.tmptradestep.stepwise=false
      this.tmptradestep.item=null
      this.tmptradestep.clickslide=false
      this.$set(this.tmptradestep,'value',[])
      if (n===this.objectives.children.length-2){
        await this.FinishTrade()
      } else {
        this.n++;
      }
    },
    onTradeEquiStep(n,onstepwise){
      // called when equivalent btn is pressed
      if(!onstepwise){
        this.$set(this.tmptradestep,'value',[this.objectives.children[n].elements.max,this.objectives.children[n+1].elements.min,this.objectives.children[n].elements.min,this.objectives.children[n+1].elements.max]);
      }
      else {
        this.$set(this.tmptradestep,'value',[this.objectives.children[n].elements.max,this.calchalf(n+1,this.tmptradestep.item===1),this.calchalf(n,this.tmptradestep.item===0),this.objectives.children[n+1].elements.max]);
      }
      this.onFinishTradeStep(n)
    },
    onSelectTradeStep(n,item){
      // called when one of the option is pressed
      if(!this.tmptradestep.selected && item===0){
        this.$set(this.tmptradestep,'value',[this.objectives.children[n].elements.max,this.objectives.children[n+1].elements.min,this.calchalf(n,this.usestepwise),this.objectives.children[n+1].elements.max]);
      } else if (!this.tmptradestep.selected && item===1){
        this.$set(this.tmptradestep,'value',[this.objectives.children[n].elements.min,this.objectives.children[n+1].elements.max,this.objectives.children[n].elements.max,this.calchalf(n+1,this.usestepwise)]);
      }
      if(!this.tmptradestep.selected){
          this.$set(this.tmptradestep,'item', item);
        } else {
          this.$set(this.tmptradestep,'itemstepwise', item);
        }
      this.$set(this.tmptradestep,'stepwise',this.usestepwise && !this.tmptradestep.selected);
      this.tmptradestep.selected=true;
    },
        slidegoto(step){
      this.$refs.stepper_trade.goTo(step);
      window.scrollTo(0, 0);

    },
        slidenext(){
      this.$refs.stepper_trade.next();
      window.scrollTo(0, 0);
    },
        onHelp(){
      this.$q.dialog({
        component: popup_help,
        tradeoff:true,
        stepwise:this.usestepwise,
        persistent:false,
        template_text:this.template_text,
      });
    },
        onInfo(index){
      this.$q.dialog({
        component: popup_card,
        alt: this.objectives.children[index],
        obj:true,
        persistent:false,
      })
    },
      }
})
  </script>
  <style scoped>
.swingcard{
}
.swingcard_selected{
  border: 0.4rem solid #e30251;
}
.swingcard:hover{
  border: 0.2rem solid #027BE3;
  cursor: pointer;
}
.value-text{
  font-size: max(0.7vw, 10px);
}

</style>