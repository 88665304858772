<template>
  <q-dialog ref="dialog" @hide="onDialogHide" :persistent="persistent" transition-show="scale" transition-hide="scale" full-height full-width>
      <q-card class="my-card" flat bordered>
        <q-card-section >
          <div class="row q-gutter-md">
            <div class="col-12 col-md-5 self-center ">
              <q-img
                  :src=alt.elements.picture
                  contain
                  spinner-color="primary"
              />
            </div>
            <div class="col-12 col-md-5 self-center ">
              <h3 v-if="!obj">{{ alt.elements.contents[0].html.caption }}</h3>
              <div class="row q-pa-md justify-center">
                <span class="col-11 "  v-html="alt.elements.contents[0].html.content" v-if="!obj"></span>
              <span class="col-11 " v-html="alt.elements.contents.html.content" v-else></span>
              </div>
              <div class="row justify-end">
              <q-btn class="col-2" color="primary" label="OK" @click="onOKClick" />
              </div>
            </div>
          </div>
        </q-card-section>

    </q-card>
  </q-dialog>
</template>

<script>
export default {
  name:"popup_card",
  props: {
    alt:Object,
    persistent:{type:Boolean,default:false},
    obj:{type:Boolean,default:false},

  },
  methods: {
    show () {
      this.$refs.dialog.show()
    },
    hide () {
      this.$refs.dialog.hide()
    },
    onDialogHide () {
      this.$emit('hide')
    },
    onOKClick () {
      this.$emit('ok')
      this.hide()
    },
  }
}
</script>
<style>

.my-card{
  width: 100%
}

</style>
