<template>
  <PrePostSurvey @end="onEnd" />
</template>

<script>
import PrePostSurvey from "@/components/PrePostSurvey";
export default {
  name: "PostSurvey",
  components: {PrePostSurvey},
  methods:{
    async onEnd(){
      await this.$store.dispatch('cauth/setUserStatus','finished');
      await this.$store.dispatch('cauth/LogOutFinish');
    }
  }
}
</script>

<style scoped>

</style>
