import { create, all } from 'mathjs'
const math = create(all, {})

export const tradeV = (value,elements)=>{

    if(elements.interp==='linear'){
        return Vlin(value,elements)
    }else if(elements.interp==='exp'){
        return Vexp(value,elements)


    } else {
        console.error("tradeV method not implemented")
    }


}
export const tradeCalc = (mat)=>{
    let n=mat.length+1
    let norm=[]
    let B=[]
    for(let i = 0; i < n; i++) {
        norm[i]=1;
        B[i]=0
    }
    mat.push(norm) // add normalizsation to matrix
    B[n-1]=1
    return math.lusolve(mat,B) // solve equation see https://mathjs.org/docs/reference/functions/lusolve.html

}

function Vexp(value,parameter){
    const c = parameter.interpC ||0 ;
    const z = Vlin(value,parameter)
    if (c==0){
        return z
    }
    return (1-Math.exp(-1*c*z))/(1-Math.exp(-1*c))
}

function Vlin(value,parameter){
    return (value-parameter.min)/(parameter.max-parameter.min)
}
