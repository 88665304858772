<template>
  <div>
<div class="absolute-center text-h4 text-primary" v-if="redirecturl">
  {{template.REDIRECTURL}} <BR/>
  <a :href="redirecturl">{{redirecturl}}</a>
</div>
    <div class="absolute-center text-h4 text-red" v-else>
        Something is wrong
    </div>
    </div>
</template>

<script>
export default {
name: "RedirectUrl",
  async mounted() {
    if(this.redirecturl) {
      window.location.href =this.redirecturl
      await this.$store.dispatch('cauth/removedata');

    }
  },
  computed:{
    template: function(){return this.$store.getters['csurvey/getdata']('templatetrans')||{}},
    redirecturl:function(){
      let info = this.$store.getters['csurvey/getdata']('info')
      if(info.externalurl.enableexiturl){
        return this.replacetoken(info.externalurl.exiturl,this.$store.state.cauth.user.token)
      }
      return ""
    },

  },
  methods:{
    replacetoken(baseurl,token){
      if(!baseurl) return
      return baseurl.replace('%tokenid%',token)
    },
  },
}
</script>

<style scoped>

</style>
