export const sortobj = (obj,robj= false) =>{

    let tmp= Object.entries(obj).sort((a, b) => b[1] - a[1])
    if(!robj){
        return tmp //return an array with [0] key [1} Value
    } else { //return an object
        let tmp2={}
        tmp.forEach(el=>{
            tmp2[el[0]]=el[1]
        })
        return tmp2
    }
}
export const maxobj = (obj)=>{
    return Object.keys(obj).reduce(function(a, b){ return obj[a] > obj[b] ? a : b });
}

export const normobject = (obj) =>{
    let sum = sumobj(obj)
    Object.keys(obj).forEach(key => { obj[key]= obj[key] / sum})
    return obj
}

export const sumobj = (obj) => {
    let sum = 0
    Object.keys(obj).forEach(key => {
        sum = obj[key] + sum
    })
    return sum
}
