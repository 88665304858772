<template>
<div>This page doesn't exist</div>
</template>

<script>
export default {
name: "NotFoundComponent"
}
</script>

<style scoped>

</style>
