import VueJwtDecode, {decode} from "jsonwebtoken";
import {api} from "@/helpers/api";
import Notify from 'quasar/src/plugins/Notify.js';;
import router from "@/router";

const state = {
    user: null,
    id:null,
    token: null,
    message:{
        title:null,
        message:null,
        poc:null,
    }
};

const getters = {
    istest:(state) => {if(state.user) return state.user.test||false;return false},
    isAuthenticated: (state) => !!state.token&&!state.user.admin,
    user: (state) => state.user,
    userid:(state)=>state.user.id,
    isTokenValid(state) {
        try {
            const date = new Date(0);
            const decoded = decode(state.token);
            date.setUTCSeconds(decoded.exp);
            return date.valueOf() > new Date().valueOf();
        } catch (err) {
            return false;
        }
    },
};

const actions = {
    async LogIn({dispatch,commit}, token) {
        const payload = await api.logintoken(token);
        if (payload.status==="OK" && payload.token) {
                Notify.create({
                    type: 'positive',
                    message: "Valid Token --- Welcome"
                });
                await dispatch('setToken',payload.token)
                commit("setMessage",{title:null,message: null,poc:null});
                router.push({name: "SurveyIndex"});
        }else {
            dispatch('logerror',payload)
        }

    },
    async AccessIn({dispatch,commit}, {surveyid,tokenid}) {
        const payload = await api.accesstoken(surveyid,tokenid);
            if (payload.status==="OK" && payload.token) {
                Notify.create({
                    type: 'positive',
                    message: "Valid Token --- Welcome"
                });
                commit("setMessage",{title:null,message: null,poc:null});
                await dispatch('setToken',payload.token)
                router.push({name: "SurveyIndex"});
            }else {
                dispatch('logerror',payload)
            }
    },

    logerror({commit},payload){
        let ify="If you think it's an error, please contact :";
        switch (payload.message){
            case "This token does not exist":
                commit("setMessage",{title:"This token does not exist",message: "This token does not exist. <br/>"+ify,poc:payload.poc});
                break;
            case "Token disabled!":
                commit("setMessage",{title:"Your token is disabled",message: "This token is disabled. <br/>"+ify,poc:payload.poc});
                break;
            case "Survey not online":
            case "Survey disabled":
                commit("setMessage",{title:"Survey is offline",message: "This survey is not online <br/>(disabled, not open yet or outdated). <br/>"+ify,poc:payload.poc});
                break;
            case "Status : finished":
                commit("setMessage",{title:"You have finished the survey",message: "You have finished the survey and it's only possible to do it once<br/>"+ify,poc:payload.poc});
                break;
        }
    },

    async setToken({commit},token){
        await commit("setToken", token);
        await commit("setUser", VueJwtDecode.decode(token));
    },
     async setUserStatus({commit,state},status){
        await commit("setStatus",status);
        if(!state.user.test){
            await api.updateuserstatus(status);
        }

    },
    async removedata({ commit,dispatch }){
        await dispatch("csurvey/clearSurvey",null,{root:true});
        await commit("setToken", null);
        await commit("setUserlogout");
    },
    async LogOut({ commit,dispatch }) {
       await dispatch('removedata')
        Notify.create({
            type: 'info',
            message: "you have been disconnected"
        });
        commit("setMessage",{title:"You have been disconnected",message: "Thank you for your participation, if you would like to restart please reenter your token",poc:null});
        await router.push({name:"Login"});
   },
    async LogOutFinish({ commit,dispatch }) {
        await dispatch('removedata')
        Notify.create({
            type: 'info',
            message: "you have been disconnected"
        });
        commit("setMessage",{title:"You have been disconnected",message: "Thank you for your participation",poc:null});
        await router.push({name:"Login"});
    },
};
const mutations = {
    setToken(state, token) {
        if(!token){
            localStorage.removeItem("eawag-token");
        } else {
            localStorage.setItem("eawag-token", token);
        }
        state.token = token;
    },
    setUser(state,userinfo){
      state.user=userinfo;
      state.id=userinfo.id;
    },
    setStatus(state,status){
      state.user.status=status;
    },

    setUserlogout(state){
        state.user=null;
        state.id=null;
    },
    setMessage(state,{title,message,poc}){
        state.message.title=title;
        state.message.message=message;
        state.message.poc=poc;
    },

};

export default {
    namespaced:true,
    state,
    getters,
    actions,
    mutations,
};

