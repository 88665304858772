import Vue from 'vue'

import './styles/quasar.sass'
import '@quasar/extras/material-icons/material-icons.css'
import Quasar from 'quasar/src/vue-plugin.js';import Notify from 'quasar/src/plugins/Notify.js';import Dialog from 'quasar/src/plugins/Dialog.js';import Loading from 'quasar/src/plugins/Loading.js';

Vue.use(Quasar, {
    config: {
        notify: { /* look at QUASARCONFOPTIONS from the API card (bottom of page) */ },
        loading:{},
    },
    plugins: {Notify,Dialog,Loading,
    }
})
