<template>

    <div class=" fixed-center row justify-center items-center">
      <select-lang :languages="info.languages" @click="onLang"/>
    </div>
</template>

<script>
import SelectLang from "@/components/SelectLang";

export default {
name: "SurveyIndex",
  components: {SelectLang},
  data(){
    return {
      loaded:["info"],
      reentry:false
    }
  },
  methods:{
    async onLang(langid){
      await this.$store.dispatch('csurvey/setLanguage',{langid:langid,reentry:this.reentry});
      //Redirect to last url if reentry
      const lurl = this.$store.getters['csurvey/getuserinput']('lasturl')
      if(this.reentry && lurl && lurl!== "/survey/index.htm"){
        await this.$router.push(this.$store.getters['csurvey/getuserinput']('lasturl'));

      }
      //Redirect to point of entry if access token
      else if(this.user.access){
        if(this.info.externalurl.pointofentry==='rational'){
          await this.$router.push({name:"rational"})

        }
      } else {
        await this.$router.push({name:"welcome"})

      }
    }
  },
  computed:{
    info:function(){return this.$store.getters['csurvey/getdata']('info')||{languages:[]}},
    user:function(){return this.$store.getters['cauth/user']}

  },
  async mounted() {
  this.$q.loading.show({
      message: 'Loading data <br/><span class="text-primary">Hang on...</span>'
    });
    for (const field of this.loaded) {
      await this.$store.dispatch('csurvey/loadkey', field);
    }
    if(this.user.status!=='new'){
      //Get previous answers
      console.log("reentry")
      this.reentry = true;

      await this.$store.dispatch('csurvey/getUserInput');
      if(this.$store.getters['csurvey/getuserinput']('selectlanguage')){
        this.onLang(this.$store.getters['csurvey/getuserinput']('selectlanguage'));
      }
    } else{
      this.reentry = false;
      await this.$store.dispatch('cauth/setUserStatus','connected');
      // automatic jump if only one language
      if(this.info.languages.length===1){
        this.onLang(this.info.languages[0].id)
      }
    }

    this.$q.loading.hide();

  }
}
</script>

<style scoped>

</style>
