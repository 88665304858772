<template>
  <q-dialog ref="dialog" @hide="onDialogHide" :persistent="persistent" transition-show="scale" transition-hide="scale" full-height full-width>
      <q-card class="my-card" flat bordered>
        <q-card-section >
          <div class="row q-pa-md justify-center">
            <span class="col-11 " v-html="template_text.SWINGTEXT"  v-if="!tradeoff && !midvalue"></span>
            <span class="col-11 " v-html="(stepwise)?template_text.TRADEOFFTEXTSTEPWISE:template_text.TRADEOFFTEXT"  v-else-if="!midvalue"></span>
            <span class="col-11 " v-html="template_text.MIDVALUETEXT"  v-else></span>
          </div>
        </q-card-section>
      <q-card-actions align="right">
        <q-btn color="primary" label="OK" @click="onOKClick" />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script>
export default {
  name:"popup_help",
  props: {
    persistent:{type:Boolean,default:false},
    tradeoff:{type:Boolean,default:false},
    stepwise:{type:Boolean,default:false},
    midvalue:{type:Boolean,default:false},

    template_text : Object,
  },
  computed: {
  },
  methods: {
    show () {
      this.$refs.dialog.show()
    },
    hide () {
      this.$refs.dialog.hide()
    },
    onDialogHide () {
      this.$emit('hide')
    },
    onOKClick () {
      this.$emit('ok')
      this.hide()
    },
  }
}
</script>
<style>

.my-card{
  width: 100%
}

</style>
