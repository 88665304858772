<template>

  <q-stepper
      v-model="step"
      ref="stepper_obj"
      color="primary"
      class="stepper"
      animated
      header-class="hidden"
      flat
  >

    <q-step
        :name="1"
        title="Objective analysis"
        :done="step > 1"
    >
      <div class="row q-pa-md self-center">
        <span class="col-11" v-html="template.RATIONAL_CONCLUSION_OBJANAL"></span>
      </div>
      <div class="row q-pa-md" v-for="entries in Object.entries(weight)" :key="entries[0]" >
        <q-card class="col-12 col-md-2 items-center" bordered >
          <img-item  class="q-pa-lg" :obj="objectives.find(o=>o.short_name===entries[0])"
                     @onInfo="onInfo(true,objectives.indexOf(objectives.find(o=>o.short_name===entries[0])))"/>

        </q-card>
        <div class="col-12 col-md-5  self-center q-pa-md q-gutter-md">
          <q-slider :value="entries[1]" :min="0" :max="1"  readonly
                    label label-always :label-value="convperc(entries[1])+' %'"
                    color="accent"/>
        </div>
      </div>
      <q-stepper-navigation class="row q-pa-md q-gutter-md justify-end">
        <q-btn class="col-auto" @click="onNext1()" color="primary" :label="template.NEXT" />
      </q-stepper-navigation>
    </q-step>
    <q-step
        :name="2"
        title="Alternative analysis table"
        :done="step > 2"
        :disable="!learnloop"
    >
      <div class=" q-pa-md justify-center items-center">
        <div class="row justify-center">
          <span class="col-11" v-html="rational.general.conclusiontext.content[0].html.content"></span>
        </div>
        <div class="row q-pa-md justify-center">
          <q-markup-table separator="cell" bordered class="tableFixHead ">
            <thead>
            <tr>
              <th class="text-center bg-grey-3" ></th>
              <th class="text-center bg-grey-3" v-for="(alt,c) in alternativesSorted" :key="alt.short_name" >
                <img-item :obj="alt"  alt @onInfo="onInfo(false,c)" style="width:9vw"/>
              </th>
            </tr>
            </thead>
            <tbody>
              <tr class=""   v-for="entries in Object.entries(weight)" :key="entries[0]">
                <td class="text-center FixCol bg-grey-3" >
                  <img-item style="width:9vw" :obj="objectives.find(o=>o.short_name===entries[0])"
                             @onInfo="onInfo(true,objectives.indexOf(objectives.find(o=>o.short_name===entries[0])))"/>
                </td>

                <td class="text-center" v-for="(alternative) in alternativesSorted" :key="alternative.short_name">
                  <q-circular-progress
                      :value="dispmatrix(alternative.short_name,entries[0])"
                      size="6vw"
                      :thickness="0.6"
                      color="orange"
                      center-color="grey-3"
                      class="q-ma-sm text-center"
                      font-size="10px"
                      show-value
                  >
                    {{dispmatrix(alternative.short_name,entries[0]) }}%
                  </q-circular-progress>
                </td>

              </tr>
            </tbody>
          </q-markup-table>
        </div>
        <div class="row q-pa-md self-center">
          <span class="col-11" v-html="template.RATIONAL_CONCLUSION_TABLE"></span>
        </div>
      </div>
      <q-stepper-navigation class="row q-pa-md q-gutter-md justify-end">
        <q-btn class="col-auto" @click="$refs.stepper_obj.next()" color="primary" :label="template.NEXT" />
      </q-stepper-navigation>
    </q-step>

    <q-step
        :name="3"
        title="Alternative analysis ranking"
        :done="step > 3"
        :disable="!learnloop"
    >
  <div class=" q-pa-md justify-center items-center">
    <div class="row col-11 q-pa-md">
      <span v-html="template.ALTRANKING"></span>
    </div>
    <div class="row q-pa-md justify-center">
      <q-markup-table separator="horizontal" bordered class="col-md-7 col-12" >
        <thead class="bg-grey-3">
          <th></th>
          <th class="text-center"> <q-icon name="favorite" size="5vw" color="accent"/></th>
          <th class="text-center"><q-icon name="list" size="5vw" color="primary"/></th>
        </thead>
        <tbody>
        <tr class=""   v-for="(alternative,r) in alternativesSorted" :key="alternative.short_name">
          <td class="text-center bg-grey-3 " >
            <img-item style="width:9vw" :obj="alternative" alt @onInfo="onInfo(false,r)" />
          </td>
          <td class="text-center">
            <q-circular-progress
                :value="convperc(sortalt[alternative.short_name])"
                size="5vw"
                :thickness="0.6"
                color="accent"
                center-color="grey-3"
                class="q-ma-sm"
                font-size="1vw"
                show-value
            >      {{ convperc(sortalt[alternative.short_name]) }}%</q-circular-progress>
          </td>
          <td class="bg-white text-red text-center "  style="font-size: 1vw;">
            {{lastaltform.lastIndexOf(alternative.short_name)+1}} .
          </td>
        </tr>
        </tbody>
      </q-markup-table>
    </div>
    <div class="row q-pa-md ">
      <span v-html="template.RATIONAL_CONCLUSION_MATCH" v-if="check_alternative_order()"></span>
      <span v-html="template.RATIONAL_CONCLUSION_NOMATCH" v-else></span>
    </div>
    </div>
      <q-stepper-navigation class="row q-pa-md q-gutter-md justify-end">
        <q-btn class="col-auto" @click="onNext3()" color="primary" :label="template.NEXT" />
      </q-stepper-navigation>
  </q-step>
    <q-step
        :name="4"
        title="Alternative sort"
        :done="step > 4"
        :disable="!learnloop"
    >
      <div class="row q-pa-md justify-center">
        <span class="col-11 text-subtitle1" v-html="template.SORTITEMSEQ" ></span>
      </div>
      <AlternativesSort :template_text="template" :alternatives="alternatives" @form="pushFormAlt"/>

    </q-step>
    <q-step
        :name="5"
        title="MidValue"
        :done="step > 5"
        :disable="!midvalue"
    >

      <MidValue  @form="pushFormMid"/>

    </q-step>

    <q-step
        :name="6"
        title="Print Results"
        :done="step>6"
        :disable="!print"
    >
      <q-stepper-navigation class="row q-pa-md q-gutter-md justify-end">
        <span class="col-11" v-html="template.DOWNLOADWEIGHTTEXT"></span>
        <q-btn class="col-auto" @click="onDownloadWeight()" color="secondary" :label="template.DOWNLOADWEIGHT" />
        <q-btn class="col-auto" @click="onNext6()" color="primary" :label="template.NEXT" />
      </q-stepper-navigation>
    </q-step>
  </q-stepper>
</template>

<script>
import AlternativesSort from "@/components/AlternativesSort";

import ImgItem from "@/components/ImgItem";
import {normobject, sortobj, sumobj} from "@/helpers/generic";
import popup_card from "@/components/popup_card";
import {tradeV} from "@/helpers/tradeoff";
import MidValue from "./MidValue.vue";

export default {
  name: "Conclusion_rational",
  components: { ImgItem, AlternativesSort, MidValue },
  data(){return {
    step:1,
  }},
  computed:{
    form: function(){return JSON.parse(JSON.stringify(this.$store.state.csurvey.userinput.rational))},
    template: function(){return this.$store.getters['csurvey/getdata']('templatetrans')||{}},
    rational:function(){return this.$store.getters['csurvey/getdata']('rational')},
    objitems:function(){return this.rational.objectives.items||[]},
    print:function(){return this.rational.general.download_weight||false},
    learnloop:function(){return this.rational.general.learnloop||false},
    midvalue:function(){return this.rational.general.midval.general!=="off"},
    objectives:function(){
      let objectives=[]
      this.objitems.forEach(item=>{
        if(item.type==='category'&&item.children.length>0){
          item.children.forEach(obj=>{
            obj.elements.bk = item.elements.color
            obj.elements.comefromcat=true
            objectives.push(obj)
          })

          } else if(item.type==='objective'){
          objectives.push(item)
          item.elements.comefromcat=false
        }
      })
      return objectives
    },
    alternatives:function(){
      return this.rational.alternatives.items || []
    },
    weight:function(){
      if(!this.form.objectives) return {}
      let w={...this.form.objectives.root.lastvalues}
      let root = this.form.objectives.root.lastvalues
      Object.keys(root).forEach(rk=>{
        this.objitems.forEach(item=>{
          if(item.type==='category'&&item.children.find(o=>o.short_name===rk)){
          let values = this.form.objectives[item.short_name].lastvalues || 0
            Object.keys(values).forEach(val=>{ w[val]=values[val]*root[rk]})
        }
        })
      })
      w=normobject(sortobj(w,true))
      return w
    },
    matrix:function(){
      let matrix={}
        this.alternatives.forEach(o=>{
          let alt = o.short_name
        let mat = this.rational.predicmat[alt]
        let tmp = {}
        Object.keys(this.weight).forEach(obj=>{
          tmp[obj]=tradeV(mat[obj], this.objectives.find(o => o.short_name === obj).elements)*this.weight[obj]
        })
        let sum  = sumobj(tmp)
        matrix[alt] = tmp
        matrix[alt][alt] = sum

      })
      return matrix
    },
    sortalt:function(){
      let tmp={}
      this.alternatives.forEach(o=>{
        let alt = o.short_name
        tmp[alt]=this.matrix[alt][alt]
      })
      return sortobj(tmp,true)
    },
    alternativesSorted:function(){
      let sorted=[]
      Object.keys(this.sortalt).forEach(alt=>{
        sorted.push(this.alternatives.find(o=>o.short_name===alt))
      })
      return sorted
    },
    lastaltform:function(){
      return this.form.alternatives[this.form.alternatives.length-1]
    },


  },
  async mounted() {
    if(!this.form.rational_conclusion){
      this.form.rational_conclusion={}
      this.form.rational_conclusion.step=this.step;
    } else if(this.form.rational_conclusion.step){
            this.step=this.form.rational_conclusion.step;

    }
    
    await this.addmcda()
  },
  methods: {
    dispmatrix(alt, obj) {
      return this.convperc(tradeV(this.rational.predicmat[alt][obj], this.objectives.find(o => o.short_name === obj).elements))
    },
    slidenext(){
      this.$refs.stepper_obj.next();
      window.scrollTo(0, 0);
    },
    onNext1() {
      if (this.learnloop) {
        this.slidenext();
      }else if(this.midvalue){
        this.$refs.stepper_obj.goTo(5);
      }else if (this.print){
      
        this.$refs.stepper_obj.goTo(6);

      } else {
        this.$emit('continue');
      }
    },
    onNext3() {
      if (this.check_alternative_order()) {
        // if true : not need to reorder alt
        if(this.midvalue){
          this.$refs.stepper_obj.goTo(5);
        }else if (this.print){
      
        this.$refs.stepper_obj.goTo(6);

      } else {
        this.$emit('continue');

      }

      } else {
        this.slidenext()
      }
    },
    onNext6() {
        this.$emit('continue');
    },
    async addmcda() {
      if(this.form){
        let formtmp = this.form;
        formtmp.weight = this.weight;
        formtmp.mcda = this.sortalt;
        await this.$store.dispatch("csurvey/setUserInput", {
          field: 'rational',
          value: JSON.parse(JSON.stringify(formtmp))
        })
      }
    },
    onDownloadWeight(){
      window.open("/surveyprint", "_blank");
    },
    async pushFormAlt(value) {
      this.form.alternatives.push(value)
      await this.$store.dispatch("csurvey/setUserInput", {
        field: 'rational',
        value: JSON.parse(JSON.stringify(this.form))
      })
      if(this.midvalue){
        this.$refs.stepper_obj.goTo(5);
      }else  if (this.print){
      
        this.$refs.stepper_obj.goTo(6);

      } else {
        this.$emit('continue');

      }
    },
  async pushFormMid(value) {
      this.form.valuefunc=value;
      await this.$store.dispatch("csurvey/setUserInput", {
        field: 'rational',
        value: JSON.parse(JSON.stringify(this.form))
      })
     if (this.print){
       this.$refs.stepper_obj.goTo(6);
      } else {
        this.$emit('continue');
      }
    },

    check_alternative_order() {
      //return true if alternative already done
      if(this.form.alternatives.length > 2) {
        return true
      }

      let mat = Object.entries(this.sortalt)
      let mat2 = this.lastaltform
      let check = true
      mat.forEach((item, i) => {
        let degmat = [item[0]] // Add degeneracy to check order
        for (let j = 0; j < mat.length; j++) {
          if (mat[i][1] === mat[j][1] && i !== j) {
            degmat.push(mat[j][0])
          }
        }
        if (!degmat.includes(mat2[i])) {
          check = false
        }
      })
      return check

    },
    convperc(value) {
      return (Math.round(value * 100))
    },
    onInfo(obj, index) {
      this.$q.dialog({
        component: popup_card,
        alt: obj ? this.objectives[index] : this.alternatives[index],
        obj: obj,
        persistent: false,
      })
    },

  },
  props:{
  },
  watch: { // Send step modification to database
    step: {
      handler: function (newValue) {
        this.form.rational_conclusion.step=newValue
        this.$store.dispatch("csurvey/setUserInput",{field:'rational',value:JSON.parse(JSON.stringify(this.form))})
      }
    }
  },
}
</script>

<style scoped>
.tableFixHead {
  overflow: scroll;
  height : 70vh;
  width : 100%;
}
.tableFixHead thead th {
  position: sticky;
  top: 0;
  z-index: 10;
}

.FixCol{
  position: sticky;
  left: 0;
  z-index: 10;
}
</style>
