<template>
<div class="q-pa-md justify-center q-gutter-md" >
  <div class="row justify-center">
  <div class="col-11" >
  <q-card >
    <q-card-section class="bg-primary text-white" >
  <div class="text-h5 title ">{{template.WELCOME}}</div>
    </q-card-section>
    <q-separator />
    <q-card-section>
  <span v-html="welcome.html.content" ></span>
    </q-card-section>
  </q-card>
  </div>
  </div>
  <div class="row q-pa-md justify-center q-gutter-md">
    <div class="col-12 col-md-5" >
      <q-card>
        <q-card-section class="bg-secondary text-white" >
          <div class="text-subtitle1 title">{{template.POC}}</div>
        </q-card-section>
        <q-separator />
        <q-card-section>
          <poc :poc="info.poc"/>
        </q-card-section>
      </q-card>
    </div>
    <q-separator/>
    <div class="col-12 col-md-5" >
      <q-card>
        <q-card-section class="bg-accent text-white" >
          <div class="text-subtitle1 ">{{template.AGREETITLE}}</div>
        </q-card-section>
        <q-card-section>
          <div class="row q-pa-md justify-center">
            <span class="col-11 " v-html="template.AGREEMENT" ></span>
          </div>
        </q-card-section>
        <q-separator />
        <q-card-section>
          <div class="row q-gutter-md">
          <div class="col-5" >
          <q-btn  @click="onRefuse" :label="template.REFUSE" color="red"/>
          </div>
          <div class="col-5" >
          <q-btn  @click="onAgree" :label="template.AGREE" color="green"/>
          </div>
          </div>
        </q-card-section>
      </q-card>
    </div>
  </div>
</div>
</template>

<script>
import poc from '@/components/poc';

export default {
  components:{poc},
  name: "WelcomePage",
  data(){
    return {
      form:{agree:false},
      loaded:["info","templatetrans","welcome"]
    }
  },

  methods:{
    async onAgree(){
       await this.$store.dispatch('csurvey/setUserInput',{field:"agreedata",value:true});
       await this.$router.push({name:"presurvey",params: { id: 0 }});
    },
    async onRefuse(){
      await this.$store.dispatch('csurvey/setUserInput',{field:"agreedata",value:false});
      await this.$store.dispatch('cauth/LogOut');
    }
  },
  computed:{
      welcome:function(){return this.$store.getters['csurvey/getdata']('welcome')||{html:{content:"Loading",movie:null}} },
      template: function(){return this.$store.getters['csurvey/getdata']('templatetrans')||{}},
      info:function(){return this.$store.getters['csurvey/getdata']('info')||{}},
},
  async mounted() {
    this.$q.loading.show({
      message: 'Loading data <br/><span class="text-primary">Hang on...</span>'
    });
    for (const field of this.loaded) {
      await this.$store.dispatch('csurvey/loadkey', field);
    }
    this.$q.loading.hide();
  }
}
</script>

<style scoped>
  .title{
    text-transform: capitalize;
  }
</style>
