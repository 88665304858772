<template>
  <div class="row q-pa-md">
    <q-stepper
        v-model="step"
        ref="stepper"
        color="primary"
        class="col-12"
        alternative-labels
        :contracted="$q.platform.is.mobile"
    >
      <q-step
          :name="1"
          :title="template.INTROALT"
          icon="looks_one"
          :done="step > 1"
      >
        <span v-html="rational.alternatives.content[0].html.content" ></span>
        <q-stepper-navigation>
          <q-btn class="" @click="slidenext()" color="primary" :label="template.NEXT" />
        </q-stepper-navigation>

      </q-step>

      <q-step
          :name="2"
          :title="template.ALTERNATIVES"
          icon="view_comfy"
          :done="step > 2"
      >
        <div class="text-subtitle1">{{template.CLICKITEMSEQ}}</div>
        <AlternativesDisp  :alternatives="rational.alternatives.items" @continue="slidenext()"/>
      </q-step>

      <q-step
          :name="3"
          :title="template.SORTALT"
          icon="app_registration"
          :done="step > 3"
      >
        <div class="row q-pa-md justify-center">
          <span class="col-11 text-subtitle1" v-html="template.SORTITEMSEQ" ></span>
        </div>
        <AlternativesSort  :alternatives="rational.alternatives.items" @continue="slidenext()" @form="pushFormAlt"/>

      </q-step>
      <q-step
          :name="4"
          :title="template.INTROOBJ"
          icon="palette"
          :done="step > 4"

      >
        <span v-html="rational.objectives.content[0].html.content" ></span>
        <q-stepper-navigation class="row q-pa-md q-gutter-md justify-end">
         <q-btn class="col-auto" @click="slidenext()" color="primary" :label="template.NEXT" />
        </q-stepper-navigation>
      </q-step>
      <!--      Category Obj-->

      <q-step v-for="(item,index) in objchildren" :key="index"
          :name="(5+index)"
          :title="template.OBJCAT+' '+ (index+1)"
          icon="add_comment"
          :done="step >  (5+index)"

      >
        <ObjectivesSort :objectives="objitems[item]" @continue="slidenext()"
                        @form="pushFormObj(objitems[item].short_name,$event)"
                        :firsttime="index===0" :rational="rational"/>
      </q-step>
<!--      ROOT Obj-->
      <q-step
              :name="(6+objchildren.length)"
              :title=template.OBJROOT
              icon="add_comment"
              :done="step >+ (6+objchildren.length)"

      >
        <ObjectivesSort  :objectives="objroot()"
        @continue="slidenext()" @form="pushFormObj('root',$event)"
                        root :firsttime="!haschildren" :rational="rational"/>
      </q-step>
<!--      CONCLUSION-->
      <q-step
          :name="(7+objchildren.length)"
          :title=template.RATCONC
          icon="assessment"
          :done="step >+ (7+objchildren.length)"

      >
        <Conclusion_rational  @continue="onEnd()"/>
      </q-step>

      <template v-slot:navigation>
        <q-stepper-navigation>
        </q-stepper-navigation>
      </template>
    </q-stepper>
  </div>
</template>

<script>
import AlternativesDisp from "@/components/AlternativesDisp";
import AlternativesSort from "@/components/AlternativesSort";
import ObjectivesSort from "@/components/ObjectivesSort";
import Conclusion_rational from "@/components/Conclusion_rational";

export default {
name: "RationalSurvey",
  components: {Conclusion_rational, AlternativesSort, AlternativesDisp,ObjectivesSort},
  data(){return{
    loaded:["templatetrans","rational","info"],
    form:{},
    step:1,
  }
  },
  computed:{
    redirect:function(){
      let info = this.$store.getters['csurvey/getdata']('info')
      return (info.externalurl.enableexiturl && info.externalurl.pointofexit==="rational")
    },
    template: function(){return this.$store.getters['csurvey/getdata']('templatetrans')||{}},
    rational:function(){return this.$store.getters['csurvey/getdata']('rational')||
        {alternatives:
          {content:[{html:{content:null}}]},
          objectives:{content:[{html:{content:null}}]},
        general:{learnloop:true}
        }
          },
    objitems:function(){return this.rational.objectives.items||[]},
    objchildren:function(){
      let children=[]
      this.objitems.forEach((item,i)=>{
        if(item.type==='category'&&item.children.length>0){
          children.push(i);
        }
      })
      return children
    },
    haschildren:function(){

      return this.objchildren.length > 0

    },

    learnloop:function(){
      return this.rational.general.learnloop
    },

  },
  methods:{
    async onEnd(){
      if(this.redirect) {
        await this.$store.dispatch('cauth/setUserStatus','finished');
        this.$router.push({name: "redirecturl"})
      } else {
        this.$router.push({name: "postsurvey",params: { id: 0 }})
      }
    },
    rootobj:function (){
      let rootobj=[]
      if(!this.form.objectives) return
      this.objchildren.forEach(o=>{
        if(this.form.objectives[this.objitems[o].short_name]) {
          let obj_sn = this.form.objectives[this.objitems[o].short_name].rootobj
          if(obj_sn){
            const max=this.objitems[o].children.find(ob=>ob.short_name===obj_sn)
            max.elements.bk = this.objitems[o].elements.color
            max.elements.comefromcat=true
            rootobj.push(max)
          }
        }
      })
      return rootobj
    },
    objroot:function(){
      let root=this.rootobj()||[]
      this.objitems.forEach((item)=>{
        if(item.type==='objective'&&item.enable){
          root.push(item);
        }
      })
      return {children:root}
    },
    pushFormAlt(value){
      this.form.alternatives.push(value)
      this.form.step=this.step
      this.$store.dispatch("csurvey/setUserInput",{field:'rational',value:JSON.parse(JSON.stringify(this.form))})
  },
    pushFormObj(obj,value){
      this.form.objectives[obj]=value
      this.form.step=this.step
      this.$store.dispatch("csurvey/setUserInput",{field:'rational',value:JSON.parse(JSON.stringify(this.form))})
    },
    slidenext(){
      this.$refs.stepper.next();
      window.scrollTo(0, 0);
    }
  },
  async created(){

    this.$q.loading.show({
      message: 'Loading data <br/><span class="text-primary">Hang on...</span>'
    });
    for (const field of this.loaded) {
      await this.$store.dispatch('csurvey/loadkey', field);
    }
    this.$q.loading.hide();
    this.form=JSON.parse(JSON.stringify(this.$store.getters['csurvey/getuserinput']('rational')));
    if (this.form.step) this.step=this.form.step;
    if(!this.form.alternatives) this.form.alternatives=[];
    if(!this.form.objectives) this.form.objectives={};

  },
  mounted(){
        if (this.form.step) this.step=this.form.step;
  },
  watch: { // Send step modification to database
    step: {
      handler: function (newValue) {
        this.form.step=newValue
        this.$store.dispatch("csurvey/setUserInput",{field:'rational',value:JSON.parse(JSON.stringify(this.form))})
      }
    }
  },
}
</script>

<style scoped>
.my-stepper{
  width:100%;
}
</style>
