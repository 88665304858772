import axios from "axios";
import store from "../store";
import Notify from 'quasar/src/plugins/Notify.js';

axios.defaults.baseURL = process.env.VUE_APP_API_URL

axios.interceptors.request.use(
    (config) => {

        if (store.getters['cauth/isAuthenticated']) {
            const token = localStorage.getItem('eawag-token');
            config.headers['Authorization'] = `Bearer ${ token }`;
        }

        return config;
    },

    (error) => {
        if (error.response.status === 401) {
            store.dispatch('cauth/LogOut');

        }
        return Promise.reject(error);
    }
);
axios.interceptors.response.use(  //TODO : Adapt for client return ?
    function(response) {
        if(process.env.NODE_ENV !== 'production') console.log(response)
        const payload=response.data;
        if (payload.status==="OK"){
            return payload
        } else {
            Notify.create({
                type: 'warning',
                message: payload.message
            })
            return payload
        }
    },
    function(error) {
        // handle error
        Notify.create({
            type: 'negative',
            message: "Error with the api :" +error.message
        });
        //
        return {status:"Error",message:error.message}
    });
export const api = {
    // Token
    logintoken: async token => {
        return await axios.post('tokens/login', {token: token});
    },
    accesstoken: async (surveyid,tokenid) => {
        return await axios.post('tokens/access', {token: tokenid,survey_id:surveyid});
    },
    updateuserstatus: async status => {
        return await axios.put('/token/me/status', {status: status});
    },
    getuserinput: async () => {
        return await axios.get('/token/me/answers');
    },
    updateuserinput: async userinput => {
        return await axios.put('/token/me/answers', {answers: userinput});
    },
    surveyinfo: async () => {
        return await axios.get('surveys/me/infos');
    },
    setlang: async lang => {
        return await axios.post('/surveys/me/languages/' + lang);
    },
    surveygetkey: async key => {
        return await axios.get('/surveys/me/get/' + key);
    },


}
