<template>
  <div class="q-pa-md justify-center q-gutter-md">

    <q-card v-for="(item,index) in data" :key="item.short_name" ref='input_card'>
      <q-card-section>
        <span v-html="item.contents[0].html.content" ></span>
      </q-card-section>
      <q-card-section>
        <span class="text-overline" v-html="item.contents[0].html.inputs" ></span>
        <component :is="item.type+'_input'"  :attribut="item.type_attribut" :ref="item.short_name+'_input'"
                   :template_text="template" v-model="form[item.short_name]" :language="$store.getters['csurvey/selectlanguage']"
                   @next="onNext(index)" :forcenext="!finishsurvey&&force_next(index,item.short_name)" :mandatory="item.mandatory" :dnext="!finishsurvey&&item.short_name===survey[id].short_name"
        />
      </q-card-section>
      <q-card-actions v-if="finishsurvey" class="column">
        <q-btn class="col self-end"  push icon-right="navigate_next" :label="template.FINISH" @click="onNext(index)" color="primary"/>
      </q-card-actions>
    </q-card>

  </div>
</template>

<script>
import {Notify} from "quasar";

export default {
  name: "PrePostSurvey",
  components: {
    'buttons_input' : () => import("@/components/form_input/buttons_input"),
    'checkboxes_input' : () => import("@/components/form_input/checkboxes_input"),
    'dragdrop_input' : () => import("@/components/form_input/dragdrop_input"),
    'numerical_input' : () => import("@/components/form_input/numerical_input"),
    'numslide_input' : () => import("@/components/form_input/numslide_input"),
    // 'stickslide_input' : () => import("@/components/form_input/stickslide_input"),
    'string_input' : () => import("@/components/form_input/string_input"),
    'text_input' : () => import("@/components/form_input/text_input"),
  },
  data(){
    return {
      key:this.pre?'presurvey':'postsurvey',
      form:{},
      loaded:[],
      scrolled:false,
    }

  },
  methods:{
    async onNext(){
      let ok = []
      for(let item of this.data){
        ok.push(await this.$refs[item.short_name+'_input'][0].validate())
      }
      if(!ok.every(item=>item===true)) {
        Notify.create({type: 'warning',
          message: `Please check your answer : some mandatory fields are empty or incorrect.`})
        return null
      }
      this.$store.dispatch("csurvey/setUserInput",{field:this.key,value:{...this.form}})

      if(!this.end){
        await this.$router.push({name:this.key,params: { id: this.id + 1 } })
      } else {
        this.$emit('end')
      }

    },
    force_next(index,name) {
      if (name === this.survey[this.id].short_name) {
        this.scrolled=false;
        this.$nextTick(function () {
            if(!this.scrolled){
              this.$refs['input_card'][index].$el.scrollIntoView({behavior: 'smooth'})
              this.scrolled=true;
            }
        })
        if (this.id < this.survey.length - 1 && this.survey[this.id + 1].long) return true;
        return (this.long || this.end)
      }
      return false
    },

  },
  computed:{
    template: function(){return this.$store.getters['csurvey/getdata']('templatetrans')||{}},
    survey:function(){return this.$store.getters['csurvey/getdata'](this.key)||{}},
    id:function(){ let id =parseInt(this.$route.params.id)
      if( id<this.survey.length && id >= 0 ) return id; return null },
    data:function(){
      if(this.id=== null) return null
      let items = [];
      items.push(this.survey[this.id])
      if(!this.long){
        for(let i=this.id-1;i>=0;i--){
          const item=this.survey[i];
          if(item.long) break;
          items.unshift(item);
        }
      }
      return items;
    },
    end:function(){return this.id===this.survey.length-1 },
    long:function(){return this.survey[this.id].long},
    finishsurvey:function(){return this.end&&!this.pre}

  },
  async mounted() {
    this.loaded=["templatetrans",this.key];
    this.form={...this.$store.getters['csurvey/getuserinput'](this.key)};
    this.$q.loading.show({
      message: 'Loading data <br/><span class="text-primary">Hang on...</span>'
    });
    for (const field of this.loaded) {
      await this.$store.dispatch('csurvey/loadkey', field);
    }
    this.$q.loading.hide();
    if(this.survey.length===0)this.$emit('end') //quit automaticaly if survey is empty
  },
  props:{
    pre:{
      type:Boolean, //if true pre survey if false post
      default:false,
    }
  },
}
</script>

<style scoped>

</style>
