<template>
<div >
        <div class="q-pa-md" style="page-break-inside: avoid;">
      <h2>{{template.CONCLUSION_TITLE_ALT}}</h2>

          <table  separator="horizontal" bordered style=" page-break-inside: avoid;">
            <thead>
              <tr>
                <th style="font-size:14pt" class="text-center bg-grey-3">{{template.CONCLUSION_ICON}}</th>
                <th style="font-size:14pt" class="text-center bg-grey-3">{{template.CONCLUSION_NAME}}</th>
                <th style="font-size:14pt" class="text-center bg-grey-3">{{template.CONCLUSION_DESCRIPTION}}</th>
              </tr>
            </thead>
            <tbody>

            <tr class="" v-for="obj in alternatives" :key="obj.short_name" >

        <td class="text-center bg-grey-3 ">
        <img-item style="width:3cm" :obj="obj" disable_i/>
        </td>
        <td class="text-center ">
          <span  style="font-size:12pt" v-html="obj.elements.contents[0].html.caption"/>
        </td>
        <td class="text-center ">
          <span  style="font-size:12pt" v-html="obj.elements.contents[0].html.content"/>
        </td>

        </tr>
        </tbody>

          </table>
      
      </div>

      <div class="q-pa-md" style="page-break-inside: avoid;">
        <h2>{{template.CONCLUSION_TITLE_OBJ}}</h2>

        <span style="font-size:12pt" v-html="rational.objectives.content[0].html.content" ></span>
      
          <table  separator="horizontal" bordered style="page-break-inside: avoid;">
            <thead>
              <tr>
                <th style="font-size:14pt" class="text-center bg-grey-3">{{template.CONCLUSION_ICON}}</th>
                <th style="font-size:14pt" class="text-center bg-grey-3">{{template.CONCLUSION_DESCRIPTION}}</th>
              </tr>
            </thead>
            <tbody>

            <tr class="" v-for="obj in objectives" :key="obj.short_name" >
        <td class="text-center bg-grey-3 ">
        <img-item style="width:3cm" :obj="obj" disable_i/>

        </td>
        <td class="text-center ">
          <span  style="font-size:12pt" v-html="obj.elements.contents.html.content"/>
        </td>

        </tr>
        </tbody>

          </table>
      
      </div>



      <div  class="q-pa-md" style="page-break-inside: avoid;">
        <h2>{{template.CONCLUSION_TITLE_WEIGHT}}</h2>

        <span  style="font-size:12pt" v-html="template.RATIONAL_CONCLUSION_OBJANAL"></span>
      <table  separator="horizontal" bordered style="width:10cm;page-break-inside: avoid;">
         <thead>
              <tr>
                <th style="font-size:14pt" class="text-center bg-grey-3">{{template.CONCLUSION_ICON}}</th>
                <th style="font-size:14pt" class="text-center bg-grey-3">{{template.CONCLUSION_WEIGHT}}</th>
              </tr>
          </thead>
            <tbody>

      <tr class="" v-for="entries in Object.entries(weight)" :key="entries[0]" >
          <td class="text-center bg-grey-3 ">
            <img-item  style="width:3cm"  :obj="objectives.find(o=>o.short_name===entries[0])"
                     disable_i/>
          </td>
          
        <td style="width:50%">
          <q-slider :value="entries[1]" :min="0" :max="1"  readonly
                    label label-always :label-value="convperc(entries[1])+' %'"
                    color="accent"/>
        
          </td>
                  
      </tr>
      </tbody>
      </table>
      </div>


      <div class="q-pa-md"  style="page-break-inside: avoid;" v-if="learnloop">
         <h2>{{template.CONCLUSION_TITLE_MATRIX}}</h2>

          <span  style="font-size:12pt" v-html="rational.general.conclusiontext.content[0].html.content"></span>
          <table separator="cell" bordered style="page-break-inside: avoid;">
            <thead>
            <tr>
              <th class="text-center bg-grey-3" ></th>
              <th class="text-center bg-grey-3" v-for="alt in alternativesSorted" :key="alt.short_name" >
                <img-item :obj="alt"  alt disable_i style="width:3cm"/>
              </th>
            </tr>
            </thead>
            <tbody>
              <tr class=""   v-for="entries in Object.entries(weight)" :key="entries[0]">
                <td class="text-center  bg-grey-3" >
                  <img-item style="width:3cm" :obj="objectives.find(o=>o.short_name===entries[0])"
                            disable_i/>
                </td>

                <td class="text-center" v-for="(alternative) in alternativesSorted" :key="alternative.short_name">
                  <q-circular-progress
                      :value="dispmatrix(alternative.short_name,entries[0])"
                      size="3cm"
                      :thickness="0.6"
                      color="orange"
                      center-color="grey-3"
                      class="q-ma-sm text-center"
                      font-size="10pt"
                      show-value
                  >
                    {{dispmatrix(alternative.short_name,entries[0]) }}%
                  </q-circular-progress>
                </td>

              </tr>
            </tbody>
          </table>
          <span style="font-size:12pt" v-html="template.RATIONAL_CONCLUSION_TABLE"></span>
      </div>

  <div class="q-pa-md"  style="page-break-inside: avoid;"  v-if="learnloop">
          <h2>{{template.CONCLUSION_TITLE_ALTRANK}}</h2>

      <span style="font-size:12pt" v-html="template.ALTRANKING"></span>
      <table  separator="horizontal" bordered style="width:15cm; page-break-inside: avoid;" >
        <thead class="bg-grey-3">
          <th></th>
          <th class="text-center"> <q-icon name="favorite" size="3cm" color="accent"/></th>
          <th class="text-center"><q-icon name="list" size="3cm" color="primary"/></th>
        </thead>
        <tbody>
        <tr class=""   v-for="alternative in alternativesSorted" :key="alternative.short_name">
          <td class="text-center bg-grey-3 " >
            <img-item style="width:3cm" :obj="alternative" alt disable_i />
          </td>
          <td class="text-center">
            <q-circular-progress
                :value="convperc(sortalt[alternative.short_name])"
                size="3cm"
                :thickness="0.6"
                color="accent"
                center-color="grey-3"
                class="q-ma-sm"
                font-size="10pt"
                show-value
            >      {{ convperc(sortalt[alternative.short_name]) }}%</q-circular-progress>
          </td>
          <td class="bg-white text-red text-center "  style="font-size: 12pt;">
            {{lastaltform.lastIndexOf(alternative.short_name)+1}} .
          </td>
        </tr>
        </tbody>
      </table>
    </div>


      <div  class="q-pa-md" style="page-break-inside: avoid;" v-if="midvalue">
        <h2>{{template.CONCLUSION_TITLE_MIDVALUE}}</h2>

        <span  style="font-size:12pt" v-html="template.RATIONAL_CONCLUSION_MIDVALUE"></span>
      <table  separator="horizontal" bordered style="width:10cm;page-break-inside: avoid;">
         <thead>
              <tr>
                <th style="font-size:14pt" class="text-center bg-grey-3">{{template.CONCLUSION_ICON}}</th>
                <th style="font-size:14pt" class="text-center bg-grey-3" v-for="value in Object.keys(this.midvalweight[Object.keys(this.midvalweight)[0]])" :key="value">{{value}}</th>
              </tr>
            </thead>
            <tbody>

      <tr class="" v-for="entries in Object.entries(midvalweight)" :key="entries[0]" >
          <td class="text-center bg-grey-3 ">
            <img-item  style="width:3cm"  :obj="objectives.find(o=>o.short_name===entries[0])"
                     disable_i/>
          </td>
          
        <td  class="text-center" v-for="values in Object.entries(entries[1])" :key="values[0]">
          <span style="font-size: 12pt;" >{{values[1]}}</span>
          </td>
                  
      </tr>
      </tbody>
      </table>
      </div>


</div>
</template>

<script>

import ImgItem from "@/components/ImgItem";
import {normobject, sortobj, sumobj} from "@/helpers/generic";
import {tradeV} from "@/helpers/tradeoff";
export default {
  name: "Conclusion_rational_print",
  components: {ImgItem},
  data(){return {
  }},
  computed:{
    form: function(){return this.$store.state.csurvey.userinput.rational},
    template: function(){return this.$store.getters['csurvey/getdata']('templatetrans')||{}},
    rational:function(){return this.$store.getters['csurvey/getdata']('rational')},
    objitems:function(){return this.rational.objectives.items||[]},
    learnloop:function(){return this.rational.general.learnloop||false},
    midvalue:function(){return this.rational.general.midval.general!=="off"},
    objectives:function(){
      let objectives=[]
      this.objitems.forEach(item=>{
        if(item.type==='category'&&item.children.length>0){
          item.children.forEach(obj=>{
            obj.elements.bk = item.elements.color
            obj.elements.comefromcat=true
            objectives.push(obj)
          })

          } else if(item.type==='objective'){
          objectives.push(item)
          item.elements.comefromcat=false
        }
      })
      return objectives
    },

    alternatives:function(){
      return this.rational.alternatives.items
    },
    weight:function(){
      let w={...this.form.objectives.root.lastvalues}
      let root = this.form.objectives.root.lastvalues
      Object.keys(root).forEach(rk=>{
        this.objitems.forEach(item=>{
          if(item.type==='category'&&item.children.find(o=>o.short_name===rk)){
          let values = this.form.objectives[item.short_name].lastvalues
            Object.keys(values).forEach(val=>{ w[val]=values[val]*root[rk]})
        }
        })
      })
      w=normobject(sortobj(w,true))
      return w
    },
    midvalweight:function(){
      if(!this.midvalue) {return}
      return this.form.valuefunc
    },
    matrix:function(){
      let matrix={}
        this.alternatives.forEach(o=>{
          let alt = o.short_name
        let mat = this.rational.predicmat[alt]
        let tmp = {}
        Object.keys(this.weight).forEach(obj=>{
          tmp[obj]=tradeV(mat[obj], this.objectives.find(o => o.short_name === obj).elements)*this.weight[obj]
        })
        let sum  = sumobj(tmp)
        matrix[alt] = normobject(tmp)
        matrix[alt][alt] = sum

      })
      return matrix
    },
    sortalt:function(){
      let tmp={}
      this.alternatives.forEach(o=>{
        let alt = o.short_name
        tmp[alt]=this.matrix[alt][alt]
      })
      return normobject(sortobj(tmp,true))
    },
    alternativesSorted:function(){
      let sorted=[]
      Object.keys(this.sortalt).forEach(alt=>{
        sorted.push(this.alternatives.find(o=>o.short_name===alt))
      })
      return sorted
    },
    lastaltform:function(){
      return this.form.alternatives[this.form.alternatives.length-1]
    },


  },
  async mounted() {

  },
  methods: {
    dispmatrix(alt, obj) {
      return this.convperc(tradeV(this.rational.predicmat[alt][obj], this.objectives.find(o => o.short_name === obj).elements))
    },




    convperc(value) {
      return (Math.round(value * 100))
    },


  },
  props:{
  }
}
</script>

<style scoped>
@media print {
* {
    -webkit-print-color-adjust: exact !important; /*Chrome, Safari */
    color-adjust: exact !important;  /*Firefox*/
  }
}



html {
    display: table;
    margin: auto;
}

body {
    display: table-cell;
    vertical-align: middle;
    line-break: normal;
}
</style>
