<template>
  <PrePostSurvey pre @end="onEnd" />
</template>

<script>
import PrePostSurvey from "@/components/PrePostSurvey";
export default {
  name: "PreSurvey",
  components: {PrePostSurvey},
  methods:{
    onEnd(){
       this.$router.push({name:"rational"});
    }
  }
}
</script>

<style scoped>

</style>
