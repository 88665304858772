<template>
    <div class="justify-center items-center">
      <div class="row justify-center q-pa-md" v-if="message.title">
        <q-card  bordered  class="shadow-3 col-md-7 col-12 ">
          <q-card-section class="bg-accent text-white text-h5 text-center " >
            {{message.title}}
          </q-card-section>
          <q-card-section class="bg-grey-2  text-h5 text-center " >
            <span v-html="message.message"/>
          </q-card-section>
          <q-card-section class="text-center " v-if="message.poc">
            <poc :poc="message.poc" />
          </q-card-section>
        </q-card>
      </div>
        <div class="row justify-center q-pa-md" >
                <q-card  bordered  class="shadow-3 col-md-7 col-12">
                  <q-card-section class="bg-primary text-white text-h5 text-center q-pa-md" >
                    Welcome to this interactive tool where you can express your value-focused preferences!
                  </q-card-section>
                  <q-card-section class="bg-grey-2 text-subtitle1 text-center q-pa-md">
                      <p>You can access it upon invitation. <br/>
                      Please copy-paste the token you received from the person who gave you the link to this tool.<br/>
                        Enjoy!</p>
                  </q-card-section>
                  <q-card-section class="bg-grey-2 text-subtitle1 q-pa-md">
                    <q-form @submit="SubmitLogin" class="row q-gutter-lg justify-center">
                              <q-input square outlined v-model="form.token"   label="Token" class="col-6" bg-color="white">
                                  <template v-slot:append>
                                      <q-icon name="vpn_key"/>
                                  </template>
                              </q-input>

                          <q-btn label="Access" type="submit" size="lg"  color="green" class="col-auto"/>
                    </q-form>
                  </q-card-section>

                </q-card>
      </div>
      <div class="row justify-center q-pa-md">
        <q-card  bordered  class="col-md-5 col-11  shadow-3">
          <q-card-section class="bg-grey-3 col-5 text-center q-pa-md">
            <div class="row justify-center">
            <img src="../../public/logoeawag.png" alt="logo eawag" class="col-5" />
            </div>
          </q-card-section>
          <q-card-section class="bg-grey-2 col-7 text-center row  justify-center">
            <div class="text-h6 col-11 text-center self-center">Contact</div>
            <div class="text-subtitle1 col-11 self-center ">
            <p>This software is provided by: <br/>
              <a href="https://www.eawag.ch/en/department/ess/main-focus/decision-analysis-da/" target="_blank">Decision Analysis Cluster </a> <br/>
              Department Environmental Social Sciences  <br/>
              Eawag: Swiss Federal Institute of Aquatic Science and Technology <br/>
              Switzerland
            </p>
            </div>
              <div class="text-h6 col-11 text-center self-center">About</div>
            <div class="text-subtitle1 col-11 self-center">
            <p>
              Version: {{version}}, Last Update: {{ date }} <br/>
              Concept: Alice Aubert <br/>
              Design: Alice Aubert and Antoine Masson (Youmi)<br/>
              Programming: <a href="https://www.youmi-lausanne.ch" target="_blank">Youmi</a><br/>
            © Eawag, 2021<br/>
            </p>
              <p>
                Funded by SNSF grant <a href="http://p3.snf.ch/project-173973 " target="_blank"> #173973</a>.
              </p>
            </div>
          </q-card-section>

        </q-card>
      </div>
    </div>
</template>

<script>

    import Poc from "@/components/poc";
    export default {
        name: 'Login',
      components: {Poc},
      data () {
            return {
              version : process.env.VUE_APP_VERSION,
              date: process.env.VUE_APP_BUILDTIME,
              form: {
                token: '',
              }
            }
        },
        methods: {
            SubmitLogin() {
             this.$store.dispatch('cauth/LogIn',this.form.token);
          },
        },
      computed:{
          message:function () {return this.$store.state.cauth.message}
      }

    }
</script>

<style scoped>

</style>


