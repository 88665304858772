<template>
<div class="q-pa-md ">
  <div class="row items-end q-pa-md">
    <div class="col-12 col-md-4 q-pa-sm " v-for="(alt,index) in alternatives" :key="alt.short_name" >
      <q-img
          :src=alt.elements.picture
          @click="onImg(index)"
          v-bind:class="{ image: !form.includes(alt.short_name),image_selected: form.includes(alt.short_name) }"
     :disabled="form.includes(alt.short_name)" 
                   spinner-color="primary">
        <q-icon class="absolute all-pointer-events"  size="sm" name="info" color="primary" style="top: 8px; left: 8px" @click.stop="onInfo(index)">
          <q-tooltip>
            {{template.READALT}}
          </q-tooltip>
        </q-icon>
        <div class="absolute-bottom  text-center">
          <div style="font-size: 1.2vh;  white-space: pre-line">{{ alt.elements.contents[0].html.caption }}</div>
        </div>
        <div class="absolute-top-right text-h3 fa-bold bg-white" style="color:#e30251" v-if="form.includes(alt.short_name)">
          {{form.indexOf(alt.short_name)+1}}
        </div>
      </q-img>
    </div>
  </div>
  <div class="row q-pa-md q-gutter-md justify-end" >
    <div class="col-auto  " v-if="form.length>0">
    <q-btn @click="onRestart" color="secondary" :label="template.RESTART"/>
  </div>
    <div class="col-auto"  v-if="dispnext" >
    <q-btn @click="onContinue" color="primary" :label="template.NEXT" />
    </div>
  </div>
</div>
</template>

<script>
import popup_card from "@/components/popup_card";
export default {
  name: "AlternativesSort",
  data(){return {
    form: [],
    dispnext:false,
  }},
 
  props:{
    alternatives:Array,
  },

  methods:{
    onRestart(){
      this.dispnext=false;
      this.form=[];
    },
    async onContinue(){
      await this.$emit('form',this.form)
      await this.$emit('continue')
    },
    onImg(index){
      const sn = this.alternatives[index].short_name
      if(this.form[this.form.length-1]===sn && this.form.length<this.alternatives.length-1){ // if reclick to the last element
        this.form.pop();
        this.dispnext=false;
        return
      }
      if (this.form.includes(sn)) return // prevent reclick to a previous element
      
      this.form.push(this.alternatives[index].short_name);
      if(this.form.length===this.alternatives.length-1){
        for (let ele of this.alternatives){
          if(!this.form.includes(ele.short_name)){
            this.form.push(ele.short_name);
            break;
          }
        }
        this.dispnext=true;
      }
    },
    onInfo(index){
      this.$q.dialog({
        component: popup_card,
        alt: this.alternatives[index],
        persistent:false,
      })
    },
  },
  computed: {
    template: function () {
      return this.$store.getters['csurvey/getdata']('templatetrans') || {}
    },}
}
</script>

<style scoped>
.image{

}
.image_selected{
  border: 0.4rem solid #e30251;
}
.image:hover{
  border: 0.2rem solid #027BE3;
  cursor: pointer;
}

</style>
