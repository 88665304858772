<template>
    <q-stepper
        v-model="step"
        ref="stepper_obj"
        color="primary"
        class="stepper"
        header-class="hidden"
        flat
    >
      <q-step
          :name="1"
          title="Category Introduction"
          :done="step > 1"
          :disable="root"
      >
        <div class="row q-pa-md justify-center">
          <span class="col-11" v-if="!root" v-html="objectives.elements.contents.html.content" ></span>
        </div>
        <q-stepper-navigation class="row q-pa-md q-gutter-md justify-end">
          <q-btn class="col-auto" @click="slidenext" color="primary" :label="template_text.NEXT" />
        </q-stepper-navigation>

      </q-step>
      <q-step
          :name="2"
          title="Objectives Presentation"
          :done="step > 2"
          >
        <div class="text-subtitle1">{{template_text.CLICKITEMSEQ}}</div>

        <div class="col row items-end q-pa-md">
      <div class="col-12 col-md-3  col-lg-2 q-pa-sm " v-for="(obj,index) in objectives.children" :key="obj.short_name" >
        <img-item :obj="obj" @onImg="onImgShow(index)" v-bind:class="{ image: index<=enablebox }" :disabled="index>enablebox" disable_i/>

      </div>
      <div class="col row q-pa-md">
        <q-btn :label ="template_text.OBJKNOWNBTN" color="primary" @click="onFinishStep()" v-if="root&&!firsttime" />
      </div>
        </div>

      </q-step>
      <q-step
          :name="3"
          title="Swing"
          :done="step > 3"
          >
      <Swing :objectives="objectives" :root="root" :rational="rational" :firsttime="firsttime&&restart==0" @finish="onFinishStep"/>

      </q-step>
      <q-step
          :name="4"
          title="TradeOff"
          :done="step > 4"
          >
      <TradeOff :objectives="objectives" :root="root" :rational="rational" :firsttime="firsttime&&restart==0" @finish="onFinishStep"/>

      </q-step>


        <q-step :name="5"
                title="Comparison"
                :done="step >  5"

        >
          <div class="row q-pa-md" v-for="entries in Object.entries(comparison.swing).sort((a, b) => b[1] - a[1])" :key="entries[0]" >
            <q-card class="col-12 col-md-2 items-center" bordered :style="(!root)?'background-color:'+objectives.elements.color:''" >
                    <img-item  class="q-pa-lg" :obj="objectives.children.find(o=>o.short_name===entries[0])"
                               @onInfo="onInfo(objectives.children.indexOf(objectives.children.find(o=>o.short_name===entries[0])))"/>

            </q-card>
            <div class="col-12 col-md-5  self-center q-pa-md q-gutter-md">
              Swing  : <q-slider :value="entries[1]" :min="0" :max="1"  readonly color="accent"/>
              Tradeoff  : <q-slider :value="comparison.tradeoff[entries[0]]" :min="0" :max="1"  readonly color="warning"/>
            </div>
          </div>

          <q-stepper-navigation class="row q-pa-md q-gutter-md justify-end" v-if="check_learningloop()">
              <div class="col-11 text-h5 text-red">{{template_text.MATCH}}</div>

              <q-btn class="col-11" @click="onFinish('good')" color="primary" :label="template_text.NEXT" />
          </q-stepper-navigation>
          <q-stepper-navigation  v-else-if="form.check.length>0">
            <div class="row q-gutter-md justify-end" v-if="!comparison.continuewithoutmatch">
              <div class="col-11 text-h5 text-red">{{template_text.NOMATCHCONTINUE}}</div>
              <q-btn class="col-11" @click="comparison.continuewithoutmatch=true" color="primary" :label="template_text.NEXT" />
              <q-btn class="col-11" @click="onFinish('rswing')" color="accent" :label="template_text.RSWING" />
              <q-btn class="col-11" @click="onFinish('rtradeoff')" color="warning" :label="template_text.RTRADEOFF" text-color="black"/>
            </div>
            <div class="row q-gutter-md justify-end" v-else>
              <div class="col-11 text-h5 text-red">{{template_text.NOMATCHSELECT}}</div>
              <q-btn class="col-11" @click="onFinish('cswing')" color="accent" :label="template_text.CSWING" />
              <q-btn class="col-11" @click="onFinish('ctradeoff')" color="warning" :label="template_text.CTRADEOFF" />
              <q-btn class="col-11" @click="onFinish('cnone')" color="secondary" :label="template_text.CNONE" />

            </div>
          </q-stepper-navigation>
          <q-stepper-navigation class="row q-pa-md q-gutter-md justify-end" v-else>
              <div class="col-11 text-h5  ">
                <span v-html="template_text.NOMATCH" ></span>
              </div>
              <q-btn class="col-11" @click="onFinish('rswing')" color="accent" :label="template_text.RSWING" />
              <q-btn class="col-11" @click="onFinish('rtradeoff')" color="warning" :label="template_text.RTRADEOFF" />
            </q-stepper-navigation>

        </q-step>
        <q-step :name="6"
                title="Restart"
                :done="step >  6"

        > 
        <div v-if="rational.general.swing">
          <div class="row q-pa-md" v-for="entries in Object.entries(comparison.swing).sort((a, b) => b[1] - a[1])" :key="entries[0]" >
            <q-card class="col-12 col-md-2 items-center" bordered :style="(!root)?'background-color:'+objectives.elements.color:''" >
                    <img-item  class="q-pa-lg" :obj="objectives.children.find(o=>o.short_name===entries[0])"
                               @onInfo="onInfo(objectives.children.indexOf(objectives.children.find(o=>o.short_name===entries[0])))"/>

            </q-card>
            <div class="col-12 col-md-5  self-center q-pa-md q-gutter-md">
              <span>Swing  : <q-slider :value="entries[1]" :min="0" :max="1"  readonly color="accent"/></span>
            </div>
          </div>
        </div>
        <div v-if="rational.general.tradeoff">
          <div class="row q-pa-md" v-for="entries in Object.entries(comparison.tradeoff).sort((a, b) => b[1] - a[1])" :key="entries[0]" >
            <q-card class="col-12 col-md-2 items-center" bordered :style="(!root)?'background-color:'+objectives.elements.color:''" >
                    <img-item  class="q-pa-lg" :obj="objectives.children.find(o=>o.short_name===entries[0])"
                               @onInfo="onInfo(objectives.children.indexOf(objectives.children.find(o=>o.short_name===entries[0])))"/>

            </q-card>
            <div class="col-12 col-md-5  self-center q-pa-md q-gutter-md">
              <span>Trade Off  : <q-slider :value="entries[1]" :min="0" :max="1"  readonly color="warning"/></span>
            </div>
          </div>
          </div>
          <q-stepper-navigation>
            <div class="row q-gutter-md justify-end" >
              <div class="col-11 text-h5">{{template_text.RESTARTSORT}}</div>
              <q-btn class="col-11" @click="onFinish('noretry')" color="primary" :label="template_text.NEXT" />
              <q-btn class="col-11" @click="onFinish('rswing')" color="accent" :label="template_text.RSWING" v-if="rational.general.swing"/>
              <q-btn class="col-11" @click="onFinish('rtradeoff')" color="warning" :label="template_text.RTRADEOFF" text-color="black" v-if="rational.general.tradeoff"/>
            </div>
            </q-stepper-navigation>
        </q-step>
    </q-stepper>
</template>

<script>
import popup_card from "@/components/popup_card";

import {maxobj, sortobj} from "@/helpers/generic";
import ImgItem from "@/components/ImgItem";

import Swing from "@/components/Swing";
import TradeOff from "@/components/TradeOff";


export default {
  name: "ObjectivesSort",
  components: {ImgItem,Swing,TradeOff},
  data(){return {
    enablebox:0,
    restart:0,
    step:1,
    form:{swing:[],tradeoff:[],step:null,check:[],lastvalues:null,rootobj:null},
    comparison:{
      swing:{},
      tradeoff:{},
      continuewithoutmatch:false
    },

  }},
  computed:{
    template_text: function(){return this.$store.getters['csurvey/getdata']('templatetrans')||{}},
  },
  methods:{

    check_learningloop:function(){
      // Return true if swing and tradeoff match
      let matswing=sortobj(this.comparison.swing)
      if(matswing.length===0 || (this.comparison.tradeoff.length === 0 || matswing.some(row => row.includes(null)) ||  Object.values(this.comparison.tradeoff).includes(null))) return
      let check = true;
      //Loop over top 5 (or length of obj)
      for(let i=0;i<Math.min(matswing.length,5);i++){
          //Check if element short_name are in the same order and error of 10%
          if ( Math.abs(matswing[i][1] - this.comparison.tradeoff[matswing[i][0]]) > 0.1) {
            check = false;
            break
          }

      }
      return check
    },

    onContinue(){
      this.$emit('form',this.form);
      this.$emit('continue');
    },
    makeroot(data){
      this.form.rootobj=maxobj(data);
      this.form.lastvalues = data;
    },
    onFinishStep(inputdata){
      //Called when either swing, tradeoff or comparison are finished
      let settings = this.rational.general;
      switch(this.step){
        case 2:
          if (settings.learnloop){
            //if ll=on we check the order
            if(settings.order==='ST'){
              //start swing
              this.slidegoto(3);
            }else if(settings.order==='TS'){
              //start tradeoff
              this.slidegoto(4);
            }else{
              console.error("something is wrong")
            }
          }else{
            //LL=off 
            if(settings.swing){
              //start swing
              this.slidegoto(3);
            }
            else if (settings.tradeoff){
              //start tradeoff
              this.slidegoto(4);
            }
            else {
              console.error("something is wrong")
            }
          }
          break;
        case 3:
          this.form.swing.push(inputdata);
          this.$emit('form',this.form);
          this.comparison.swing=inputdata;

          if(this.restart>0){
            this.slidegoto(this.restart);
          }else{
          if (settings.learnloop){
            //if ll=on we check the order
            if(settings.order==='ST'){
              //start tradeoff
              this.slidegoto(4);
            }else if(settings.order==='TS'){
              //start comparison
              this.slidegoto(5);
            }else{
              console.error("something is wrong")
            }
          }else{
            //LL=off 
            if(settings.prop_retry){
              //start retry
              this.slidegoto(6);
            } else {
              //finished
              this.$emit("pushroot",this.makeroot(inputdata));
              this.onContinue();
            }
          }}
          break;
        case 4:
          this.form.tradeoff.push(inputdata);
          this.$emit('form',this.form);
          this.comparison.tradeoff=inputdata;
          if(this.restart>0){
            this.slidegoto(this.restart);
          }else{
          if (settings.learnloop){
            //if ll=on we check the order
            if(settings.order==='ST'){
              //start comparison
              this.slidegoto(5);
            }else if(settings.order==='TS'){
              //start swing
              this.slidegoto(3);
            }else{
              console.error("something is wrong")
            }
          }else{
            //LL=off 
            if(settings.prop_retry){
              //start retry
              this.slidegoto(6);
            } else {
              //finished

              this.$emit("pushroot",this.makeroot(inputdata));
              this.onContinue();
            }
          }}
          break;


      }

    },


    onFinish(status){
      this.form.check.push(status)
      if(status==="good") {
        this.$emit('form', this.form)
        this.$emit("pushroot", this.makeroot(this.comparison.swing))
        this.onContinue()
      } else if(status==="rswing"){
          this.restart = this.step ;
          //start swing
          this.slidegoto(3);
      }else if(status==="rtradeoff"){
          this.restart = this.step ;
          //start tradeoff
          this.slidegoto(4);
      }else if(status==='cswing'){
        this.$emit('form', this.form)
        this.$emit("pushroot", this.makeroot(this.comparison.swing))
        this.onContinue()
      }else if(status==='ctradeoff'){
        this.$emit('form', this.form)
        this.$emit("pushroot", this.makeroot(this.comparison.tradeoff))
        this.onContinue()
      }else if(status==='cnone'){
        this.$emit('form', this.form)
        let mat={}
        this.objectives.children.forEach(o=>{mat[o.short_name]=1.0/this.objectives.children.length}) //Create a equi prob matrix
        this.$emit("pushroot", this.makeroot(mat))
        this.onContinue()
      }else if(status==='noretry'){
        if(this.rational.general.swing){
          this.$emit('form', this.form)
          this.$emit("pushroot", this.makeroot(this.comparison.swing))
          this.onContinue()
        } else if(this.rational.general.tradeoff){
          this.$emit('form', this.form)
          this.$emit("pushroot", this.makeroot(this.comparison.tradeoff))
          this.onContinue()
        } else {
          console.error("Something is wrong")
        }

      }
    },


    onImgShow(index){
      if(index>this.enablebox) return //box is disabled so nothing happends
      this.$q.dialog({
        component: popup_card,
        alt: this.objectives.children[index],
        obj:true,
        persistent:true,
      }).onOk(() => {
        if(index===this.enablebox) this.enablebox++; //move to the next image
      })
      if(index===this.objectives.children.length-1) this.onFinishStep() //send a continue event all images have been displayed

    },

    onInfo(index){
      this.$q.dialog({
        component: popup_card,
        alt: this.objectives.children[index],
        obj:true,
        persistent:false,
      })
    },
    slidenext(){
      this.$refs.stepper_obj.next();
      window.scrollTo(0, 0);
    },
    slidegoto(step){
      this.step = step;
      this.$refs.stepper_obj.goTo(step);
      window.scrollTo(0, 0);

    }
  },
  props:{
    objectives:Object,
    root:{type:Boolean,default:false},
    rational:Object,
    firsttime:{type:Boolean,default:false},
    alternatives:Array,
    predicmat:Object,
  },
  created() {
  },
  mounted() {

    if(this.root){
      this.step=2
      this.slidegoto(this.step)
    }
  },
  watch: { // Send step modification to database
    step: {
      handler: function (newValue) {
        this.form.step=newValue
        this.$emit('form',this.form)
      }
    }
  },
}
</script>

<style scoped>

.image{

}
.image:hover{
  border: 0.2rem solid #027BE3;
  cursor: zoom-in;
}
</style>
