<template>
  <q-stepper
    v-model="step"
    ref="stepper_midval"
    color="primary"
    class="stepper"
    header-class="hidden"
    flat
    style="padding:0px"
    v-if="objectives.length > 0"
  >
    <q-step
      :name="1"
      title="MidValue presentation"
      icon="looks_one"
      :done="step > 1"
    >
      <div class="row q-pa-md justify-center">
        <span class="col-11" v-html="template_text.MIDVALUETEXT"></span>
      </div>
      <q-stepper-navigation class="row q-pa-md q-gutter-md justify-end">
        <q-btn
          class="col-auto"
          @click="slidenext()"
          color="primary"
          :label="template_text.NEXT"
        />
      </q-stepper-navigation>
    </q-step>

    <q-step :name="2" title="MidValue" :done="step > 2">
      <div class="row q-pa-md justify-center q-gutter-md">
        <span class="col-10 text-subtitle1" v-html="template_text.MIDVALSEL" />
        <q-btn
          class="col-auto"
          :label="template_text.HELPBTN"
          icon="help"
          @click="onHelp()"
        />
      </div>

      <div class="row q-pa-md justify-center q-gutter-md">
        <q-card bordered class="col-md-1 col-12 self-center">
          <img-item :obj="objectives[n]" @onInfo="onInfo(n)" />
        </q-card>

        <div class="col-md-10  self-center column q-gutter-md">
          <q-slider
            class="col"
            readonly
            color="orange"
            :selection-color="
              objectives[n].elements.max < objectives[n].elements.min
                ? 'pink-9'
                : 'blue-10'
            "
            :track-color="
              objectives[n].elements.max < objectives[n].elements.min
                ? 'blue-10'
                : 'pink-9'
            "
            label
            label-always
            :reverse="objectives[n].elements.max < objectives[n].elements.min"
            :value="elements_val"
            :min="TOSlider('min', n)"
            :max="TOSlider('max', n)"
            :marker-labels="[
              {
                value: objectives[n].elements.min,
                label: objectives[n].elements.min,
              },
              {
                value: objectives[n].elements.max,
                label: objectives[n].elements.max,
              },
              { value: calchalf(n), label: calchalf(n) },
            ]"
          />
          <div class="col row justify-between">
            <div class=" col-md-4 col-12  column q-gutter-md">
              <q-card class="row  " bordered>
                <q-card class="col-6 row bg-blue-10">
                  <span
                    class=" text-h3 col self-center text-center text-white"
                    >{{ objectives[n].elements.min }}</span
                  >
                </q-card>

                <q-card class=" col-6 row bg-orange">
                  <span
                    class=" text-h3 col self-center text-white text-center"
                    >{{ elements_val }}</span
                  >
                </q-card>
              </q-card>
              <div class="row justify-center" v-if="isNo">
                <q-btn
                  class="col-6"
                  :label="template_text.MIDVALUELOWBTN"
                  color="primary"
                  @click="onChange(false)"
        
                />
       
              </div>
            </div>
            <div class="col-md-2 col-12 items-center q-pa-md row">
              <span class=" text-h3  col self-center text-center">{{
                template_text.VSTEXT
              }}</span>
            </div>
            <div class=" col-md-4 col-12  column q-gutter-md">
              <q-card class="row " bordered>
                <q-card class=" col-6 row bg-orange">
                  <span
                    class=" text-h3 col self-center text-white text-center"
                    >{{ elements_val }}</span
                  >
                </q-card>

                <q-card class=" col-6 row bg-pink-9 ">
                  <span
                    class=" text-h3 col self-center text-center text-white"
                    >{{ objectives[n].elements.max }}</span
                  >
                </q-card>
              </q-card>
              <div class="row justify-center" v-if="isNo">
      
                <q-btn
                  class="col-6"
                  :label="template_text.MIDVALUEHIGHBTN"
                  color="primary"
                  @click="onChange(true)"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row justify-center q-pa-md q-gutter-md " v-if="!isNo">
        <div class="col-md-5 col-12 self-center"></div>
        <div class="col-md-2 col-12 justify-center ">
            <div class=" column q-gutter-md q-pa-md ">
          <span class="col text-center">{{ template_text.MIDVALUEYESNO }}</span>
          <q-btn
            class="col" 
            :label="template_text.MIDVALUEYESBTN"
            color="primary"
            @click="onYes"
          />
          <q-btn
            class="col"
            :label="template_text.MIDVALUENOBTN"
            color="primary"
            @click="onNo"
          />
          <q-btn
            class="col"
            :label="template_text.MIDVALUERETRYBTN"
            color="secondary"
            @click="onRetry"
            v-if="midstep > 0"
          />
          </div>
        </div>
        <div class="col-md-4 col-12 self-center"></div>
      </div>
      
    </q-step>
  </q-stepper>
</template>
<script>
import { defineComponent } from "@vue/composition-api";
import ImgItem from "@/components/ImgItem";
import popup_help from "@/components/popup_help";
import popup_card from "@/components/popup_card";
import { sortobj } from "@/helpers/generic";

export default defineComponent({
  name: "MidValue",
  components: { ImgItem },
  computed: {
    template_text: function() {
      return this.$store.getters["csurvey/getdata"]("templatetrans") || {};
    },
    rational: function() {
      return this.$store.getters["csurvey/getdata"]("rational");
    },
    rational_form: function() {
      return this.$store.state.csurvey.userinput.rational;
    },
  },
  data() {
    return {
      form: {},
      n: 0,
      step: 1,
      midstep: 0,
      isNo: false,
      elements_val_prev: 0,
      objectives: [],
      elements_val: 0,
    };
  },
  mounted() {
    const allobj = [];
    this.$store.state.csurvey.data.rational.objectives.items.forEach((key) => {
      if (key.type === "objective") allobj.push(key);
      if (key.children) {
        key.children.forEach((key) => {
          if (key.type === "objective") allobj.push(key);
        });
      }
    });
    if (this.rational.general.midval.general === "auto") {
      const w = sortobj(this.rational_form.weight);
      const wmax = this.rational.general.midval.auto_threshold;
      let sumw = 0;
      w.every((ele) => {
        sumw = sumw + ele[1];
        allobj.find((o, i) => {
          if (o.short_name === ele[0]) {
            this.objectives.push(allobj[i]);
            return true;
          }
        });
        if (sumw >= wmax) {
          return false;
        }
        return true;
      });
    } else if (this.rational.general.midval.general === "manual") {
      allobj.forEach((obj) => {
        if (obj.midval_manual_enable) {
          this.objectives.push(obj);
        }
      });
    } else {
      console.error("something wrong here");
    }

    this.onRetry();
  },

  methods: {
    onChange(higher) {
      let max = this.objectives[this.n].elements.max;
      let min = this.objectives[this.n].elements.min;
      if (min > max) {
        const tmp = min;
        min = max;
        max = tmp;
        higher =  !higher;
      }
      const step = this.objectives[this.n].elements.step ?? 1;
      const valsav = this.elements_val;
      let nstep = Math.abs( (max - min) / (2**(this.midstep +2)));
      if (higher) {
        this.elements_val = this.elements_val + nstep;
      } else {
        this.elements_val = this.elements_val - nstep;
      }
      this.elements_val = Math.round(this.elements_val / step) * step; // round to the step
      if (
        this.elements_val == valsav ||
        this.elements_val >= max ||
        this.elements_val <= min
      ) {
        this.elements_val = valsav;
        this.$q
          .dialog({
            title: this.template_text.MIDVALUEBOXTITLE,
            message: this.template_text.MIDVALUEBOXMESSAGE,
            ok: {
              color: "green",
              label: this.template_text.MIDVALUEYESBTN,
            },
            cancel: {
              label: this.template_text.MIDVALUERETRYBTN,
              color: "primary",
            },
            persistent: true,
          })
          .onOk(() => {
            this.onYes();
          })
          .onCancel(() => {
            this.onRetry();
          });
      }
      this.elements_val_prev = valsav;
      this.isNo = false;
      this.midstep++;
    },
    async onYes() {
      this.form[this.objectives[this.n].short_name] = {
        0.5: this.elements_val,
      };
      this.n++;
      if (this.n >= this.objectives.length) {
        // end midvalue
        await this.$emit("form", this.form);
      }
      this.onRetry();
    },
    onNo() {
      this.isNo = true;
    },
    onRetry() {
      this.isNo = false;
      this.midstep = 0;
      this.elements_val = this.calchalf(this.n);
    },

    TOSlider(minmax, n) {
      let max = this.objectives[n].elements.max;
      let min = this.objectives[n].elements.min;
      //let step = this.objectives[n].elements.step ?? 1;

      if (min > max) {
        // invert min and max so slider react correctly
        const tmp = max;
        max = min;
        min = tmp;
      }

      if (minmax === "min") {
        return min;
      } else {
        return max;
      }
    },
    calchalf(n) {
      // if enabled return half of objective values / if disabled return min
      const max = this.objectives[n].elements.max;
      const min = this.objectives[n].elements.min;
      const step = this.objectives[n].elements.step ?? 1;
      let half = (max - min) / 2.0 + min;
      half = Math.ceil(half / step) * step; // round to the step
      return half;
    },
    slidenext() {
      this.$refs.stepper_midval.next();
      window.scrollTo(0, 0);
    },
    onHelp() {
      this.$q.dialog({
        component: popup_help,
        tradeoff: false,
        midvalue: true,
        persistent: false,
        template_text: this.template_text,
      });
    },
    onInfo(index) {
      this.$q.dialog({
        component: popup_card,
        alt: this.objectives[index],
        obj: true,
        persistent: false,
      });
    },
  },
});
</script>
<style scoped>
.value-text {
  font-size: 4vh;
}
</style>
