import Vue from 'vue'
import Vuex from 'vuex'

import auth from './modules/auth'
import survey from './modules/survey'

import { createLogger } from 'vuex'
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

export default new Vuex.Store({
    modules: {
        cauth:auth,
        csurvey:survey,
    },
    strict: debug,
    plugins: debug ? [createLogger(),createPersistedState()] : [createPersistedState()]
})
