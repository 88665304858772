<template>
  <q-layout view="lHr LpR lfr">

    <q-header elevated class="bg-red-14 text-white" v-if="test">
      <q-toolbar>

        <q-toolbar-title>
          Test mode
        </q-toolbar-title>
        <div class="row q-gutter-md">
          <q-toggle v-model="debug" label="debug" color="green"/>
        <q-btn label="Reload" color="primary" @click="onReload"/>
        <q-btn label="Logout" color="secondary" @click="onLogout"/>
        </div>
      </q-toolbar>
    </q-header>

    <q-drawer show-if-above  reveal flat  side="left"  content-class="bg-blue-grey-2" v-if="$q.platform.is.desktop" :breakpoint="1400" :width="$q.screen.width*0.15">
      <div v-if="test&&debug">
        Userinput :
        <vue-json-pretty  :path="'res'"
                             :data="userinput" />


      </div>

    </q-drawer>

    <q-drawer show-if-above reveal flat side="right"  content-class="bg-blue-grey-2 " v-if="$q.platform.is.desktop" :breakpoint="1400" :width="$q.screen.width*0.15">
    </q-drawer>

    <q-page-container>
      <router-view />
    </q-page-container>

  </q-layout>
</template>

<script>
import VueJsonPretty from 'vue-json-pretty'
import 'vue-json-pretty/lib/styles.css'
export default {

name: "Main",
  data(){
  return {
    debug:false,
  }
  },
  components: {
    VueJsonPretty
  },
  computed:{
    test:function(){return this.$store.getters['cauth/istest']},
    userinput:function(){return this.$store.state.csurvey.userinput}
  },
  methods:{
  onReload(){
    this.$store.dispatch('csurvey/ReloadAll');
  },
    onLogout(){
      this.$store.dispatch('cauth/LogOut');
    },

  }
}
</script>

<style lang="sass">


</style>
